import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Chip,
} from "@mui/material";
import "./scholarships.css";

const gwaOptions = [
  "1.0",
  "1.25",
  "1.5",
  "1.75",
  "2.0",
  "2.25",
  "2.5",
  "2.75",
  "3.0",
  "3.25",
  "3.5",
  "3.75",
  "4.0",
  "4.25",
  "4.5",
  "4.75",
  "5.0",
];

const AdminCreateScholarship = () => {
  const [name, setName] = useState("");
  const [formLink, setFormLink] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [gwa, setGwa] = useState("");
  const [initialQty, setInitialQty] = useState("");
  const [endDate, setEndDate] = useState("");
  const [images, setImages] = useState([]);
  const [links, setLinks] = useState([]);


  useEffect(() => {
    
    axios
      .get("https://bsusms.online/api/scholarshipchoices") 
      .then((response) => {
        setLinks(response.data);
      })
      .catch((error) => {
        console.error("Error fetching form links:", error);
      });
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get("https://bsusms.online/api/departments");
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments", error);
      }
    };

    fetchDepartments();
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]); // Set selected images
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Get the selected department names
      const departmentNames = selectedDepartments.map((dep) => dep.name);
  
      // Create the JSON structure for the database
      const departmentsJson = { departments: departmentNames };
  
      // Prepare the data for the scholarship
      const scholarshipData = {
        name,
        // exclusive_for: exclusiveFor,
        form_link: formLink,
        departments: departmentsJson, // Send department names as an object
        gwa,
        initial_qty: initialQty,
        end_date: endDate,
        images: [] // Placeholder for images
      };
  
      // If images are selected, include them in the request body (file names)
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          scholarshipData.images.push(images[i].name);  // We are saving the filenames in the database
        }
      }
  
      // Send the scholarship data (with image file names) and images together
      const formData = new FormData();
      
      // Append scholarship data to FormData (serialize it)
      formData.append("scholarshipData", JSON.stringify(scholarshipData));
  
      // Append the image files to FormData
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);  // Add image file(s)
      }
      console.log("Selected Department Names:", departmentNames);
      console.log("Departments JSON:", departmentsJson);
      console.log("Scholarship Data (before sending):", scholarshipData);
      console.log("Scholarship Data (JSON string):", JSON.stringify(scholarshipData));
      

      for (let [key, value] of formData.entries()) {
        console.log(`FormData Entry - ${key}:`, value);
      }
  
      // Send the FormData to the server for both scholarship data and images
      await axios.post("https://bsusms.online/api/scholarship/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // This is important to handle the file upload
        },
      });
  
      alert("Scholarship created successfully!");
      setName("");
      // setExclusiveFor("");
      setFormLink("");
      setSelectedDepartments([]);
      setGwa("");
      setInitialQty("");
      setEndDate("");
      setImages([]);  // Clear the images after submission
  
    } catch (error) {
      console.error("Error creating scholarship", error);
      alert("Failed to create scholarship");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="scholarship-form">
      {/* Scholarship Name */}
      <div className="form-group">
        <label>Scholarship Name:</label>
        <TextField value={name} onChange={(e) => setName(e.target.value)} required fullWidth />
      </div>

      {/* Form Link */}
      <div className="form-group">
      <label>Form Link:</label>
      <TextField
        select // Enables the TextField as a dropdown
        value={formLink}
        onChange={(e) => setFormLink(e.target.value)}
        required
        fullWidth
      >
        <MenuItem value="">
          <em>--Select a form link--</em>
        </MenuItem>
        {links.map((link) => (
          <MenuItem key={link.id} value={link.originalname}>
            {link.originalname}
          </MenuItem>
        ))}
      </TextField>
    </div>

      {/* Department Selection */}
      <div className="form-group">
        <Autocomplete
          multiple
          options={departments}
          getOptionLabel={(option) => option.name}
          value={selectedDepartments}
          onChange={(event, value) => setSelectedDepartments(value)}
          renderInput={(params) => <TextField {...params} label="Select Departments" />}
        />
      </div>

      {/* Initial Quantity */}
      <div className="form-group">
        <label>Initial Quantity:</label>
        <TextField type="number" value={initialQty} onChange={(e) => setInitialQty(e.target.value)} required fullWidth />
      </div>

      {/* Validity End Date */}
      <div className="form-group">
        <label>Validity End Date:</label>
        <TextField type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required fullWidth InputLabelProps={{ shrink: true }} />
      </div>

      {/* GWA */}
      <div className="form-group">
        <FormControl fullWidth>
          <InputLabel>GWA</InputLabel>
          <Select value={gwa} onChange={(e) => setGwa(e.target.value)} required>
            {gwaOptions.map((option, index) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Image Upload */}
      <div className="form-group">
        <label>Upload Images:</label>
        <input type="file" multiple onChange={handleImageChange} />
      </div>

      <div className="form-group-button">
        <Button type="submit" variant="contained" color="primary">Create Scholarship</Button>
      </div>
    </form>
  );
};

export default AdminCreateScholarship;
