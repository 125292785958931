import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook to fetch program data from the API
export const useProgramData = () => {
  const [programData, setProgramData] = useState([]);

  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/top-programs"
        );
        setProgramData(response.data);
      } catch (error) {
        console.error("Error fetching program data:", error);
      }
    };

    fetchProgramData();
  }, []);

  return programData;
};
