// src/components/AdminDashboard.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Carousel,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import "./demotable.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';

const AdminDashboard = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newImage, setNewImage] = useState(null);

  // Fetch images from backend on component mount
  useEffect(() => {
    fetchImages();
  }, []);

  // Function to fetch images from the backend
  const fetchImages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://bsusms.online/api/carousel-images"
      );
      setImages(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch images");
      setLoading(false);
    }
  };

  // Function to handle image upload
  const handleImageUpload = (e) => {
    setNewImage(e.target.files[0]);
  };

  // Function to add new image to carousel
  const addImage = async () => {
    if (!newImage) return;

    const formData = new FormData();
    formData.append("image", newImage);

    try {
      setLoading(true);
      await axios.post("https://bsusms.online/api/carousel-images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setNewImage(null);
      fetchImages();
    } catch (error) {
      setError("Failed to upload image");
    } finally {
      setLoading(false);
    }
  };

  // Function to delete an image from the carousel
  const deleteImage = async (imageId) => {
    try {
      setLoading(true);
      await axios.delete(
        `https://bsusms.online/api/carousel-images/${imageId}`
      );
      fetchImages();  // You can keep this in case you want to update the UI immediately.
    } catch (error) {
      setError("Failed to delete image");
      setLoading(false);
    }
  };
  

  return (
    <Box sx={{ minWidth: 275, marginBottom: 2 }}>
      <Card variant="outlined">
        <CardContent sx={{ textAlign: "center" }}>
          <Container className="mt-4">
            <h3>Carousel Management</h3>

            {/* Upload Image Section */}
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload New Image</Form.Label>
              <Form.Control type="file" onChange={handleImageUpload} />
              <Button
                variant="primary"
                className="mt-2"
                onClick={addImage}
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Add Image"
                )}
              </Button>
            </Form.Group>

            {error && <Alert variant="danger">{error}</Alert>}

            {/* Carousel Preview */}
            <div
              className="custom-carousel"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/bsumalvar.jpg)`,
              }}
            >
              {images.length > 0 ? (
                <Carousel>
                  {images.map((image) => (
                    <Carousel.Item key={image.id}>
                      <img
                        className="d-block w-100"
                        src={image.url}
                        alt="Carousel slide"
                      />
                      <Carousel.Caption>
                        <Button
                          variant="danger"
                          onClick={() => deleteImage(image.id)}
                        >
                          Delete
                        </Button>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <p>No images in carousel</p>
              )}
            </div>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminDashboard;
