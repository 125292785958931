import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./home.js";
import ScholarshipList from './scholarshipList.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import RequirementStatus from './applicationStatus.js';
import "./tab.css";

const StudentDashboard = () => {
  const [scholar, setScholar] = useState(null);  // State to store scholar status
  const token = localStorage.getItem('authToken');  // Assuming the JWT is stored in localStorage

  // Fetch the scholar status from the backend
  useEffect(() => {
    const fetchScholarStatus = async () => {
      if (!token) {
        console.error("No token found");
        return;
      }

      try {
        const response = await fetch('https://bsusms.online/api/check-tab', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,  // Set the Authorization header with the token
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Received scholar data:', data.scholar);  // Log scholar value
          setScholar(data.scholar);  // Update state with scholar value
        } else {
          const errorData = await response.text();  // Get raw text for error handling
          console.error("Error fetching scholar status:", errorData);
        }
      } catch (error) {
        console.error("Error fetching scholar status:", error);
      }
    };

    fetchScholarStatus();
  }, [token]);

  return (
    <Container>
      <Tabs defaultActiveKey="home" id="student-dashboard-tabs" className="mb-3 nav-justified flex-nowrap">
        <Tab eventKey="home" title={<> <i className="fa-solid fa-list"></i> <span className="d-none d-sm-inline">Scholarship List</span></>}>
          <ScholarshipList />
        </Tab>
        <Tab
          eventKey="scholarship-application"
          title={<> <i className="fa-solid fa-file-alt"></i> <span className="d-none d-sm-inline">Scholarship Application</span>
            </>
          }
          disabled={scholar === null || scholar === undefined}  // Disable if scholar is null or undefined
        >
          <Home />
        </Tab>
        <Tab
          eventKey="application-status"
          title={ <> <i className="fa-solid fa-check-circle"></i> <span className="d-none d-sm-inline">Scholarship Status</span>
            </>
          }
          disabled={scholar === null || scholar === undefined} // Disable if scholar is null or undefined
        >
          <RequirementStatus />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default StudentDashboard;
