import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/login_user.js";
import Dashboard from "./pages/dashboard_user";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AdminDashboard from "./pages/dashboard_admin.js";
import AdminLogin from "./pages/login_admin.js";
import ProtectedRoute from "./pages/protectedRoute.js";
import ProtectedRouteAdmin from "./pages/protectedRouteAdmin.js";
import 'alertifyjs/build/css/alertify.css';

function MainLayout() {
  const location = useLocation();

  return (
    <div
      className="shadow-lg"
      style={{
        position: "relative",
        backgroundColor: "white",
        marginTop: "0px",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0px",
        paddingTop: "0px",
        maxWidth: location.pathname.startsWith("/bsu-sms") ? "" : "1000px",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Routes>
        {/* Public Routes */}

        <Route path="/login" element={<Login />} />
        <Route path="/admin-bsu" element={<AdminLogin />} />

        {/* Protected Route */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={Dashboard} />}
        />
        <Route
          path="/bsu-sms/*"
          element={<ProtectedRouteAdmin element={AdminDashboard} />}
        />

        {/* Default Route */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

export default App;
