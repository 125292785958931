import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook to fetch department data from the API
export const useDepartmentData = () => {
  const [departmentData, setDepartmentData] = useState([]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/department-distribution"
        );
        setDepartmentData(response.data);
      } catch (error) {
        console.error("Error fetching department data:", error);
      }
    };

    fetchDepartmentData();
  }, []);

  return departmentData;
};
