// import React, { useState, useEffect } from "react";
// import { Container, Card, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import './scholarshipList.css'; // Make sure to import your CSS file

// const ScholarshipList = () => {
//   const [scholarships, setScholarships] = useState([]);
//   const [message, setMessage] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [appliedScholarships, setAppliedScholarships] = useState([]);

//   useEffect(() => {
//     const fetchScholarships = async () => {
//       try {
//         const response = await axios.get('${process.env.BSU_SMS_2024}/scholarship/all');
//         setScholarships(response.data);
//       } catch (error) {
//         console.error('Error fetching scholarships', error);
//       }
//     };
//     fetchScholarships();
//   }, []);

//   const handleApply = async (scholarshipId, scholarshipName) => {
//     try {
//       const token = localStorage.getItem('authToken');
//       if (!token) {
//         setMessage('You must be logged in to apply for scholarships.');
//         setShowAlert(true);
//         return;
//       }

//       const response = await axios.post(
//         '${process.env.BSU_SMS_2024}/users/apply',
//         { scholarshipName },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       setAppliedScholarships((prev) => [...prev, scholarshipId]);
//       setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
//       setShowAlert(true); // Show the alert after successful application
//     } catch (error) {
//       console.error('Error applying for scholarship:', error);
//       const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
//       setMessage(errorMsg);
//       setShowAlert(true); // Show the alert in case of error
//     }
//   };

//   return (
//     <Container style={{ marginBottom: '50px' }}>
//       {showAlert && (
//         <Alert
//           variant={message.startsWith('Successfully') ? 'success' : 'danger'}
//           onClose={() => setShowAlert(false)}
//           dismissible
//         >
//           <strong>{message}</strong>
//         </Alert>
//       )}
//       <div className="mb-10 page-header d-flex justify-content-between">
//         <h3 className="mb-0 page-header">Scholarship List</h3>
//       </div>
//       <Card>
//         <Card.Body style={{ marginBottom: '0px' }}>
//           <div className="overflow-auto">
//             {scholarships.length === 0 ? (
//               <p>No Scholarships Available.</p>
//             ) : (
//               scholarships.map((scholarship) => (
//                 <div
//                   key={scholarship.id}
//                   className={`scholarship-item d-flex align-items-center mb-2 ${appliedScholarships.includes(scholarship.id) ? 'applied' : ''}`}
//                 >
//                   <div className="d-flex flex-column w-100">
//                     <div className="d-flex justify-content-between">
//                       <div className="d-flex flex-column">
//                         <span className="font-weight-bold">{scholarship.name}</span>
//                       </div>
//                       <div className="d-flex flex-column">
//                         <Button
//                           className="button-white-sm"
//                           type="button"
//                           onClick={() => handleApply(scholarship.id, scholarship.name)}
//                           disabled={appliedScholarships.includes(scholarship.id)} // Disable only if this scholarship has been applied for
//                         >
//                           {appliedScholarships.includes(scholarship.id) ? 'Applied' : 'Apply'}
//                         </Button>
//                       </div>
//                     </div>
//                     <div>Exclusive For: {scholarship.exclusive_for}</div>
//                     <div>Required to Submit: <a href={scholarship.form_link} target="_blank" rel="noopener noreferrer">{scholarship.form_link}</a></div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ScholarshipList;



// import React, { useState, useEffect } from "react";
// import { Container, Card, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import './scholarshipList.css'; // Make sure to import your CSS file

// const ScholarshipList = () => {
//   const [scholarships, setScholarships] = useState([]);
//   const [message, setMessage] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [appliedScholarships, setAppliedScholarships] = useState([]);

//   useEffect(() => {
//     const fetchScholarships = async () => {
//       try {
//         const response = await axios.get('${process.env.BSU_SMS_2024}/scholarship/all');
//         setScholarships(response.data);
//       } catch (error) {
//         console.error('Error fetching scholarships', error);
//       }
//     };
//     fetchScholarships();
//   }, []);

//   const handleApply = async (scholarshipId, scholarshipName) => {
//     try {
//       const token = localStorage.getItem('authToken');
//       const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
//       if (!token || !userInfo) {
//         setMessage('You must be logged in to apply for scholarships.');
//         setShowAlert(true);
//         return;
//       }
  
//       // Include user info (id, name, email) in the request
//       const response = await axios.post(
//         '${process.env.BSU_SMS_2024}/Susers/apply',
//         {
//           userId: userInfo.id,
//           name: userInfo.name,
//           email: userInfo.email,
//           scholarshipName,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
  
//       setAppliedScholarships((prev) => [...prev, scholarshipId]);
//       setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
//       setShowAlert(true);
//     } catch (error) {
//       console.error('Error applying for scholarship:', error);
//       const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
//       setMessage(errorMsg);
//       setShowAlert(true);
//     }
//   };

//   return (
//     <Container style={{ marginBottom: '50px' }}>
//       {showAlert && (
//         <Alert
//           variant={message.startsWith('Successfully') ? 'success' : 'danger'}
//           onClose={() => setShowAlert(false)}
//           dismissible
//         >
//           <strong>{message}</strong>
//         </Alert>
//       )}
//       <div className="mb-10 page-header d-flex justify-content-between">
//         <h3 className="mb-0 page-header">Scholarship List</h3>
//       </div>
//       <Card>
//         <Card.Body style={{ marginBottom: '0px' }}>
//           <div className="overflow-auto">
//             {scholarships.length === 0 ? (
//               <p>No Scholarships Available.</p>
//             ) : (
//               scholarships.map((scholarship) => (
//                 <div
//                   key={scholarship.id}
//                   className={`scholarship-item d-flex align-items-center mb-2 ${appliedScholarships.includes(scholarship.id) ? 'applied' : ''}`}
//                 >
//                   <div className="d-flex flex-column w-100">
//                     <div className="d-flex justify-content-between">
//                       <div className="d-flex flex-column">
//                         <span className="font-weight-bold">{scholarship.name}</span>
//                       </div>
//                       <div className="d-flex flex-column">
//                         <Button
//                           className="button-white-sm"
//                           type="button"
//                           onClick={() => handleApply(scholarship.id, scholarship.name)}
//                           disabled={appliedScholarships.includes(scholarship.id)}
//                         >
//                           {appliedScholarships.includes(scholarship.id) ? 'Applied' : 'Apply'}
//                         </Button>
//                       </div>
//                     </div>
//                     <div>Exclusive For: {scholarship.exclusive_for}</div>
//                     <div>Required to Submit: <a href={scholarship.form_link} target="_blank" rel="noopener noreferrer">{scholarship.form_link}</a></div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ScholarshipList;

// import React, { useState, useEffect } from "react";
// import { Container, Card, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import './scholarshipList.css'; // Make sure to import your CSS file

// const ScholarshipList = () => {
//   const [scholarships, setScholarships] = useState([]);
//   const [message, setMessage] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [appliedScholarships, setAppliedScholarships] = useState([]);

//   useEffect(() => {
//     const fetchScholarships = async () => {
//       try {
//         const response = await axios.get('${process.env.BSU_SMS_2024}/scholarship/all');
//         setScholarships(response.data);
//       } catch (error) {
//         console.error('Error fetching scholarships', error);
//       }
//     };
//     fetchScholarships();
//   }, []);

//   useEffect(() => {
//     const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//     if (userInfo) {
//       const storedAppliedScholarships = JSON.parse(localStorage.getItem(`appliedScholarships_${userInfo.id}`)) || [];
//       setAppliedScholarships(storedAppliedScholarships);
//     }
//   }, []);

//   const handleApply = async (scholarshipId, scholarshipName) => {
//     try {
//       const token = localStorage.getItem('authToken');
//       const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//       if (!token || !userInfo) {
//         setMessage('You must be logged in to apply for scholarships.');
//         setShowAlert(true);
//         return;
//       }

//       // Make the apply request
//       await axios.post(
//         '${process.env.BSU_SMS_2024}/Susers/apply',
//         {
//           userId: userInfo.id,
//           name: userInfo.name,
//           email: userInfo.email,
//           scholarshipName,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Update applied scholarships and save them for the current user
//       const updatedAppliedScholarships = [...appliedScholarships, scholarshipId];
//       setAppliedScholarships(updatedAppliedScholarships);
//       localStorage.setItem(`appliedScholarships_${userInfo.id}`, JSON.stringify(updatedAppliedScholarships));

//       setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
//       setShowAlert(true);
//     } catch (error) {
//       console.error('Error applying for scholarship:', error);
//       const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
//       setMessage(errorMsg);
//       setShowAlert(true);
//     }
//   };

//   return (
//     <Container style={{ marginBottom: '50px' }}>
//       {showAlert && (
//         <Alert
//           variant={message.startsWith('Successfully') ? 'success' : 'danger'}
//           onClose={() => setShowAlert(false)}
//           dismissible
//         >
//           <strong>{message}</strong>
//         </Alert>
//       )}
//       <div className="mb-10 page-header d-flex justify-content-between">
//         <h3 className="mb-0 page-header">Scholarship List</h3>
//       </div>
//       <Card>
//         <Card.Body style={{ marginBottom: '0px' }}>
//           <div className="overflow-auto">
//             {scholarships.length === 0 ? (
//               <p>No Scholarships Available.</p>
//             ) : (
//               scholarships.map((scholarship) => (
//                 <div
//                   key={scholarship.id}
//                   className={`scholarship-item d-flex align-items-center mb-2 ${
//                     appliedScholarships.includes(scholarship.id) ? 'applied highlight' : ''
//                   }`} // Highlight applied scholarships
//                 >
//                   <div className="d-flex flex-column w-100">
//                     <div className="d-flex justify-content-between">
//                       <div className="d-flex flex-column">
//                         <span className="font-weight-bold">{scholarship.name}</span>
//                       </div>
//                       <div className="d-flex flex-column">
//                         <Button
//                           className="button-white-sm"
//                           type="button"
//                           onClick={() => handleApply(scholarship.id, scholarship.name)}
//                           disabled={appliedScholarships.includes(scholarship.id)} // Disable if already applied
//                         >
//                           {appliedScholarships.includes(scholarship.id) ? 'Applied' : 'Apply'}
//                         </Button>
//                       </div>
//                     </div>
//                     <div>Exclusive For: {scholarship.exclusive_for}</div>
//                     <div>Required to Submit: <a href={scholarship.form_link} target="_blank" rel="noopener noreferrer">{scholarship.form_link}</a></div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ScholarshipList;

// import React, { useState, useEffect } from "react";
// import { Container, Card, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import './scholarshipList.css'; // Make sure to import your CSS file

// const ScholarshipList = () => {
//   const [scholarships, setScholarships] = useState([]);
//   const [message, setMessage] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [appliedScholarships, setAppliedScholarships] = useState([]);
//   const [hasApplied, setHasApplied] = useState(false);

//   useEffect(() => {
//     const fetchScholarships = async () => {
//       try {
//         const response = await axios.get('${process.env.BSU_SMS_2024}/scholarship/all');
//         setScholarships(response.data);
//       } catch (error) {
//         console.error('Error fetching scholarships', error);
//       }
//     };


    
//     // Load applied scholarships from localStorage if available
//     const storedAppliedScholarships = JSON.parse(localStorage.getItem('appliedScholarships'));
//     if (storedAppliedScholarships) {
//       setAppliedScholarships(storedAppliedScholarships);
//       setHasApplied(storedAppliedScholarships.length > 0); // If user has already applied for at least one, set flag
//     }
    
//     fetchScholarships();
//   }, []);

//   const handleApply = async (scholarshipId, scholarshipName) => {
//     try {
//       const token = localStorage.getItem('authToken');
//       const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//       if (!token || !userInfo) {
//         setMessage('You must be logged in to apply for scholarships.');
//         setShowAlert(true);
//         return;
//       }

//       // Include user info (id, name, email) in the request
//       const response = await axios.post(
//         '${process.env.BSU_SMS_2024}/Susers/apply',
//         {
//           userId: userInfo.id,
//           name: userInfo.name,
//           email: userInfo.email,
//           scholarshipName,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Add the applied scholarship to the state and localStorage
//       const updatedAppliedScholarships = [...appliedScholarships, scholarshipId];
//       setAppliedScholarships(updatedAppliedScholarships);
//       localStorage.setItem('appliedScholarships', JSON.stringify(updatedAppliedScholarships));

//       setHasApplied(true); // Set the flag to disable further applications
//       setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
//       setShowAlert(true);
//     } catch (error) {
//       console.error('Error applying for scholarship:', error);
//       const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
//       setMessage(errorMsg);
//       setShowAlert(true);
//     }
//   };

//   return (
//     <Container style={{ marginBottom: '50px' }}>
//       {showAlert && (
//         <Alert
//           variant={message.startsWith('Successfully') ? 'success' : 'danger'}
//           onClose={() => setShowAlert(false)}
//           dismissible
//         >
//           <strong>{message}</strong>
//         </Alert>
//       )}
//       <div className="mb-10 page-header d-flex justify-content-between">
//         <h3 className="mb-0 page-header">Scholarship List</h3>
//       </div>
//       <Card>
//         <Card.Body style={{ marginBottom: '0px' }}>
//           <div className="overflow-auto">
//             {scholarships.length === 0 ? (
//               <p>No Scholarships Available.</p>
//             ) : (
//               scholarships.map((scholarship) => (
//                 <div
//                   key={scholarship.id}
//                   className={`scholarship-item d-flex align-items-center mb-2 ${appliedScholarships.includes(scholarship.id) ? 'applied' : ''}`}
//                 >
//                   <div className="d-flex flex-column w-100">
//                     <div className="d-flex justify-content-between">
//                       <div className="d-flex flex-column">
//                         <span className="font-weight-bold">{scholarship.name}</span>
//                       </div>
//                       <div className="d-flex flex-column">
//                         <Button
//                           className="button-white-sm"
//                           type="button"
//                           onClick={() => handleApply(scholarship.id, scholarship.name)}
//                           disabled={hasApplied || appliedScholarships.includes(scholarship.id)} // Disable if already applied or has applied to another
//                         >
//                           {appliedScholarships.includes(scholarship.id) ? 'Applied' : 'Apply'}
//                         </Button>
//                       </div>
//                     </div>
//                     <div>Exclusive For: {scholarship.exclusive_for}</div>
//                     <div>Required to Submit: <a href={scholarship.form_link} target="_blank" rel="noopener noreferrer">{scholarship.form_link}</a></div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ScholarshipList;

// import React, { useState, useEffect } from "react";
// import { Container, Card, Button, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import './scholarshipList.css'; // Make sure to import your CSS file

// const ScholarshipList = () => {
//   const [scholarships, setScholarships] = useState([]);
//   const [message, setMessage] = useState('');
//   const [showAlert, setShowAlert] = useState(false);
//   const [appliedScholarships, setAppliedScholarships] = useState([]);
//   const [userScholarship, setUserScholarship] = useState(null);

//   useEffect(() => {
//     const fetchScholarships = async () => {
//       try {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//         const userId = userInfo?.id; // Assuming userInfo contains the user ID
//         const response = await axios.get(`https://bsusms.online/api/scholarshipUser/all?userId=${userId}`); 
//         setScholarships(response.data);
  
//         if (userInfo?.scholarship) {
//           setUserScholarship(userInfo.scholarship);
//         }
//       } catch (error) {
//         console.error('Error fetching scholarships', error);
//       }
//     };
//     fetchScholarships();
//   }, []);

//   const handleApply = async (scholarshipId, scholarshipName) => {
//     try {
//       const token = localStorage.getItem('authToken');
//       if (!token) {
//         setMessage('You must be logged in to apply for scholarships.');
//         setShowAlert(true);
//         return;
//       }

//       // Make the API request to apply for the scholarship
//       const response = await axios.post(
//         'https://bsusms.online/api/users/apply',
//         { scholarshipName },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // Update applied scholarships in the state
//       setAppliedScholarships((prev) => [...prev, scholarshipId]);
//       setUserScholarship(scholarshipName);

//       // Retrieve the existing userInfo from localStorage
//       const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//       // Update the scholarship field
//       const updatedUserInfo = { ...userInfo, scholarship: scholarshipName };

//       // Save the updated userInfo back into localStorage
//       localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));

//       setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
//       setShowAlert(true); // Show the alert after successful application
//     } catch (error) {
//       console.error('Error applying for scholarship:', error);
//       const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
//       setMessage(errorMsg);
//       setShowAlert(true); // Show the alert in case of error
//     }
//   };

//   return (
//     <Container style={{ marginBottom: '50px' }}>
//       {showAlert && (
//         <Alert
//           variant={message.startsWith('Successfully') ? 'success' : 'danger'}
//           onClose={() => setShowAlert(false)}
//           dismissible
//         >
//           <strong>{message}</strong>
//         </Alert>
//       )}
//       <div className="mb-10 page-header d-flex justify-content-between">
//         <h3 className="mb-0 page-header">Scholarship List</h3>
//       </div>
//       <Card>
//         <Card.Body style={{ marginBottom: '0px' }}>
//           <div className="overflow-auto">
//             {scholarships.length === 0 ? (
//               <p>No Scholarships Available.</p>
//             ) : (
//               scholarships.map((scholarship) => (
//                 <div
//                   key={scholarship.id}
//                   className={`scholarship-item d-flex align-items-center mb-2 ${userScholarship === scholarship.name ? 'applied' : ''}`}
//                 >
//                   <div className="d-flex flex-column w-100">
//                     <div className="d-flex justify-content-between">
//                       <div className="d-flex flex-column">
//                         <span className="font-weight-bold">{scholarship.name}</span>
//                       </div>
//                       <div className="d-flex flex-column">
//                         <Button
//                           className="button-white-sm"
//                           type="button"
//                           onClick={() => handleApply(scholarship.id, scholarship.name)}
//                           disabled={userScholarship !== null} // Disable if already applied for a scholarship
//                         >
//                           {userScholarship === scholarship.name ? 'Applied' : 'Apply'}
//                         </Button>
//                       </div>
//                     </div>
//                     <div>Exclusive For: {scholarship.exclusive_for}</div>
//                     <div>Required to Submit: <a href={scholarship.form_link} target="_blank" rel="noopener noreferrer">{scholarship.form_link}</a></div>
//                     <div><strong>Available Slot: {scholarship.qty}</strong></div>
//                     <div>
//                       Validity Date: {new Date(scholarship.end_date).toLocaleDateString("en-US", {
//                         year: "numeric",
//                         month: "long",
//                         day: "numeric"
//                       })}
//                     </div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ScholarshipList;

import React, { useState, useEffect } from "react";
import { Container, Card, Button, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import './scholarshipList.css';

const ScholarshipList = () => {
  const [scholarships, setScholarships] = useState([]);
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [appliedScholarships, setAppliedScholarships] = useState([]);
  const [userScholarship, setUserScholarship] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedScholarship, setSelectedScholarship] = useState(null);

  useEffect(() => {
    const fetchScholarships = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const userId = userInfo?.id;

        const response = await axios.get(`https://bsusms.online/api/scholarshipUser/all?userId=${userId}`);
        const scholarshipsData = Array.isArray(response.data) ? response.data : [];
        setScholarships(scholarshipsData);

        if (userInfo?.scholarship) {
          setUserScholarship(userInfo.scholarship);
        }
      } catch (error) {
        console.error('Error fetching scholarships', error);
        setScholarships([]);
      }
    };
    fetchScholarships();
  }, []);

  const handleApply = async (scholarshipId, scholarshipName) => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setMessage('You must be logged in to apply for scholarships.');
        setShowAlert(true);
        return;
      }

      const response = await axios.post(
        'https://bsusms.online/api/users/apply',
        { scholarshipName },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setAppliedScholarships((prev) => [...prev, scholarshipId]);
      setUserScholarship(scholarshipName);

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const updatedUserInfo = { ...userInfo, scholarship: scholarshipName };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));

      setMessage(`Successfully applied for the ${scholarshipName} scholarship.`);
      setShowAlert(true);
    } catch (error) {
      console.error('Error applying for scholarship:', error);
      const errorMsg = error.response?.data?.message || 'Error applying for the scholarship. Please try again.';
      setMessage(errorMsg);
      setShowAlert(true);
    }
  };

  const handleViewDetails = (scholarship) => {
    let images = [];
    try {
      images = JSON.parse(scholarship.images);  // Parsing images array from JSON
    } catch (error) {
      console.error('Images field is not valid JSON:', error);
      images = scholarship.images;
    }
    setSelectedScholarship({ ...scholarship, images });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedScholarship(null);
  };

  return (
    <Container style={{ marginBottom: '50px' }}>
      {showAlert && (
        <Alert
          variant={message.startsWith('Successfully') ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <strong>{message}</strong>
        </Alert>
      )}
      <div className="mb-10 page-header d-flex justify-content-between">
        <h3 className="mb-0 page-header">Scholarship List</h3>
      </div>
      <Card>
        <Card.Body style={{ marginBottom: '0px' }}>
          <div className="overflow-auto">
            {Array.isArray(scholarships) && scholarships.length > 0 ? (
              scholarships.map((scholarship) => (
                <div
                  key={scholarship.id}
                  className={`scholarship-item d-flex align-items-center mb-2 ${userScholarship === scholarship.name ? 'applied' : ''}`}
                >
                  <div className="d-flex flex-column w-100">
                  <div className="d-flex justify-content-between align-items-center">
  <span className="font-weight-bold">{scholarship.name}</span>
  <div className="d-flex gap-3">
    <Button
      className="button-white-sm"
      type="button"
      onClick={() => handleViewDetails(scholarship)}
    >
      View Details
    </Button>
    <Button
      className="button-white-sm ml-2"  // Added margin-left to space out buttons
      type="button"
      onClick={() => handleApply(scholarship.id, scholarship.name)}
      disabled={userScholarship !== null}
    >
      {userScholarship === scholarship.name ? 'Applied' : 'Apply'}
    </Button>
  </div>
</div>

                    <div>Exclusive For: {scholarship.exclusive_for}</div>
                    <div>Required to Submit: <a href={scholarship.form_link} download rel="noopener noreferrer">{scholarship.form_link}</a></div>
                    <div>
                      {!(scholarship.initial_qty === 0 && scholarship.qty === 0) && (
                        <div><strong>Available Slot: {scholarship.qty}</strong></div>
                      )}
                    </div>
                    <div>
                      Validity Date: {new Date(scholarship.end_date).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      })}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Scholarships Available.</p>
            )}
            
          </div>
        </Card.Body>
      </Card>

      {selectedScholarship && (
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedScholarship.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Required GWA:</strong> {selectedScholarship.gwa}</p>
            {/* <p><strong>Departments:</strong> {selectedScholarship.departments}</p> */}
            <p><strong>End Date:</strong> {new Date(selectedScholarship.end_date).toLocaleDateString()}</p>
            {/* <p><strong>Available Slot:</strong> {selectedScholarship.qty}</p> */}
            <div><strong>Images:</strong></div>
            {Array.isArray(selectedScholarship.images) && selectedScholarship.images.length > 0 ? (
              selectedScholarship.images.map((image, index) => (
                <img key={index} src={`https://bsusms.online/${image}`} alt="Scholarship Image" style={{ width: '100%', marginBottom: '10px' }} />
              ))
            ) : selectedScholarship.images === null || selectedScholarship.images.length === 0 ? (
              <p>No images available</p>  // Display this message if no images are present
            ) : (
              <p>No images found</p> // This can be another fallback if needed
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default ScholarshipList;
