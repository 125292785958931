import React, { useState, useEffect } from 'react';
import { Carousel, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import './userCarousel.css';

const UserCarousel = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://bsusms.online/api/carousel-images');
        setImages(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load carousel images');
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  if (loading) return <Spinner animation="border" />;
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="custom-carousel"  style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bsumalvar.jpg)` }}>
      <Carousel>
        {images.map((image) => (
          <Carousel.Item key={image.id}>
            <img className="d-block w-100" src={image.url} alt="Carousel slide" />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default UserCarousel;