// changePasswordModal.js
import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField, CircularProgress } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import alertify from "alertifyjs";

const ChangePasswordModal = ({ open, onClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangePassword = async () => {
        const email = localStorage.getItem('userEmail'); // Retrieve the email

        if (!email) {
            setError('Email not found. Please log in again.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('https://bsusms.online/api/change-password', {
                email,
                currentPassword,
                newPassword
            });

            if (response.status === 200) {
                alertify.success(response.data.message);
                onClose(); // Close the modal on success
            }
        } catch (err) {
            console.error("Error response:", err.response);
            setError(err.response?.data.error || 'Error changing password');
            alertify.error(err.response?.data.error || 'Error changing password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: fullScreen ? '90%' : 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Change Password
                </Typography>
                <TextField
                    label="Current Password"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                />
                {error && <Typography color="error" variant="body2">{error}</Typography>}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleChangePassword}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Change Password'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;
