import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook to fetch municipality data from the API
export const useMunicipalityData = () => {
  const [municipalityData, setMunicipalityData] = useState([]);

  useEffect(() => {
    const fetchMunicipalityData = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/municipality-count"
        );
        setMunicipalityData(response.data);
      } catch (error) {
        console.error("Error fetching municipality data:", error);
      }
    };

    fetchMunicipalityData();
  }, []);

  return municipalityData;
};
