import React, { useEffect, useState } from 'react';


const AnnouncementCard = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://bsusms.online/api/announcements')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => setAnnouncements(data))
      .catch(error => {
        console.error('Error fetching announcements:', error);
        setError(`Failed to fetch announcements: ${error.message}`);
      });
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="card-deck">
      {announcements.map((announcement) => (
        <div key={announcement.id} className="card border-warning shadow-sm" style={{ margin: '10px' }}>
          <div className="card-body">
            <h5 className="card-title text-danger">
              <i className="fa-solid fa-circle-exclamation"></i> {announcement.title}
            </h5>
            <p className="card-text">
              {announcement.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AnnouncementCard;
