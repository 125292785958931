import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios"; // Make sure axios is imported
import alertify from "alertifyjs"; // Import Alertify.js

const NestedModal = ({ open, handleClose, email }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    try {
      const storedEmail = sessionStorage.getItem("email");
      if (!storedEmail || !verificationCode) {
        setMessage("Please enter the verification code.");
        setSuccess(false);
        return;
      }

      const response = await axios.post("https://bsusms.online/api/verify-otp", {
        email: storedEmail,
        verificationCode,
      });

      setMessage(response.data.message || "Verification successful!");
      setSuccess(true);
      alertify.success(response.data.message || "Verification successful!");

      handleClose();
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.error || "Verification failed");
      } else {
        setMessage("Error connecting to the server");
      }
      setSuccess(false);
    }
  };

  const handleCloseModal = (event) => {
    // Prevent closing the modal when clicking outside of it
    if (event.target === event.currentTarget) {
      return;
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box sx={modalStyle(isMobile)}>
        <Typography variant="h6">Email Verification</Typography>
        <Typography sx={{ mt: 2 }}>
          Please enter the verification code sent to your email:
        </Typography>
        <TextField
          variant="outlined"
          label="Verification Code"
          fullWidth
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          sx={{ mt: 2 }}
        />
        {message && (
          <Typography color={success ? "green" : "red"} sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Verify Code
        </Button>
      </Box>
    </Modal>
  );
};

// Style function for the nested modal
const modalStyle = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "90%" : "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "10px",
});

export default NestedModal;
