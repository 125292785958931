// import React, { useState, useEffect } from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
// import axios from "axios";

// const steps = ["Personal Data", "Academic Data"];
// const API_BASE = "https://psgc.gitlab.io/api";

// export default function HorizontalLinearStepper({ open, onClose }) {
//   const getProvinces = async () => {
//     const response = await axios.get(`${API_BASE}/provinces`);
//     return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
//   };

//   const getMunicipalities = async (provinceCode) => {
//     const response = await axios.get(
//       `${API_BASE}/provinces/${provinceCode}/cities-municipalities`
//     );
//     return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
//   };

//   const getBarangays = async (municipalityCode) => {
//     const response = await axios.get(
//       `${API_BASE}/cities-municipalities/${municipalityCode}/barangays`
//     );
//     return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
//   };

//   const [campuses, setCampuses] = useState([]);
//   const [selectedCampus, setSelectedCampus] = useState("");
//   const [departments, setDepartments] = useState([]);
//   const [selectedDepartment, setSelectedDepartment] = useState(""); // Updated from selectedProgram to selectedDepartment
//   const [program, setProgram] = useState("");

//   const [activeStep, setActiveStep] = useState(0);
//   const [skipped, setSkipped] = useState(new Set());

//   // Form state
//   const [formData, setFormData] = useState({
//     lastName: "",
//     firstName: "",
//     middleName: "",
//     age: "",
//     sex: "",
//     civilStatus: "",
//     province: "",
//     municipality: "",
//     barangay: "",
//     campus: selectedCampus,
//     department: selectedDepartment,
//     program: "",
//     yearLevel: "",
//     semester: "",
//     srCode: "",
//     gwa: "",
//     // email: "",
//     // password: "",
//   });

//   const [message, setMessage] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [success, setSuccess] = useState(false); // Track success state

//   const [provinces, setProvinces] = useState([]);
//   const [municipalities, setMunicipalities] = useState([]);
//   const [barangays, setBarangays] = useState([]);

//   // Fetch provinces on component mount
//   useEffect(() => {
//     const fetchProvinces = async () => {
//       const data = await getProvinces();
//       setProvinces(data);
//     };
//     fetchProvinces();
//   }, []);

//   // Fetch municipalities when province is selected
//   useEffect(() => {
//     if (formData.province) {
//       const fetchMunicipalities = async () => {
//         const data = await getMunicipalities(formData.province);
//         setMunicipalities(data);
//         setBarangays([]); // Reset barangays when province changes
//         setFormData((prev) => ({ ...prev, municipality: "", barangay: "" })); // Reset form data
//       };
//       fetchMunicipalities();
//     }
//   }, [formData.province]);

//   // Fetch barangays when municipality is selected
//   useEffect(() => {
//     if (formData.municipality) {
//       const fetchBarangays = async () => {
//         const data = await getBarangays(formData.municipality);
//         setBarangays(data);
//         setFormData((prev) => ({ ...prev, barangay: "" })); // Reset barangay when municipality changes
//       };
//       fetchBarangays();
//     }
//   }, [formData.municipality]);

//   useEffect(() => {
//     axios
//       .get("${process.env.BSU_SMS_2024}/api/campuses")
//       .then((response) => {
//         setCampuses(response.data);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching campuses!", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (selectedCampus) {
//       console.log("Selected campus ID:", selectedCampus); // Log the selected campus ID
//       axios
//         .get(`${process.env.BSU_SMS_2024}/api/departments/${selectedCampus}`)
//         .then((response) => {
//           setDepartments(response.data);
//           setSelectedDepartment(""); // Reset selected department
//           setProgram([]); // Clear majors when campus changes
//         })
//         .catch((error) => {
//           console.error("There was an error fetching departments!", error);
//         });
//     } else {
//       setDepartments([]);
//       setProgram([]);
//     }
//   }, [selectedCampus]);

//   useEffect(() => {
//     const fetchDepartments = async () => {
//       try {
//         const response = await axios.get(
//           "${process.env.BSU_SMS_2024}/api/departments"
//         );
//         setDepartments(response.data); // Store the list of departments in state
//       } catch (error) {
//         console.error("Error fetching departments:", error);
//       }
//     };

//     fetchDepartments();
//   }, []);

//   useEffect(() => {
//     if (selectedDepartment) {
//       axios
//         .get(`${process.env.BSU_SMS_2024}/api/program/${selectedDepartment}`)
//         .then((response) => {
//           setProgram(response.data);
//         })
//         .catch((error) => {
//           console.error("There was an error fetching majors!", error);
//         });
//     } else {
//       setProgram([]);
//     }
//   }, [selectedDepartment]);

//   // Handle form changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     console.log("Field name:", name); // Log the field name
//     console.log("Field value:", value); // Log the field value

//     if (name === "campus") {
//       setSelectedCampus(value);
//       console.log("Updated selectedCampus:", value); // Log updated campus
//     } else if (name === "department") {
//       setSelectedDepartment(value);
//       console.log("Updated selectedDepartment:", value); // Log updated department
//     } else {
//       setFormData((prev) => ({ ...prev, [name]: value }));
//     }
//   };

//   useEffect(() => {
//     console.log("Selected Campus:", selectedCampus);
//     console.log("Selected Department:", selectedDepartment);
//   }, [selectedCampus, selectedDepartment]);

//   const handleNext = () => {
//     // Validate form before allowing to proceed
//     if (activeStep === 0) {
//       const { lastName, firstName, middleName, age, sex, civilStatus } =
//         formData;
//       if (
//         !lastName ||
//         !firstName ||
//         !middleName ||
//         !age ||
//         !sex ||
//         !civilStatus
//       ) {
//         alert("Please complete all fields before proceeding.");
//         return;
//       }
//     } else if (activeStep === 1) {
//       const { program, yearLevel, semester, srCode, gwa } = formData;
//       if (
//         // !campus ||
//         // !department ||
//         !program ||
//         !yearLevel ||
//         !semester ||
//         !srCode ||
//         !gwa
//       ) {
//         alert("Please complete all fields before proceeding.");
//         return;
//       }
//     }

//     let newSkipped = skipped;
//     if (isStepSkipped(activeStep)) {
//       newSkipped = new Set(newSkipped.values());
//       newSkipped.delete(activeStep);
//     }

//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     setSkipped(newSkipped);
//   };

//   const handleBack = () =>
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);

//   const handleReset = () => setActiveStep(0);

//   // const isStepOptional = (step) => step === 1;

//   const isStepSkipped = (step) => skipped.has(step);

//   const modalStyle = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 800,
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     p: 4,
//     outline: "none",
//     borderRadius: "10px",
//   };

//   const handleGwaChange = (e) => {
//     const value = e.target.value;
//     setFormData((prev) => ({ ...prev, gwa: value }));

//     // Validate that the GWA is between 1.0 and 5.0
//     if (value < 1 || value > 5) {
//       setMessage("GWA must be between 1.0 and 5.0");
//     } else {
//       setMessage(""); // Clear the message if valid
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Find the campus name from the selectedCampus ID
//     const selectedCampusName = campuses.find(
//       (campus) => campus.id === selectedCampus
//     )?.name;

//     const selectedDepartmentName = departments.find(
//       (department) => department.id === selectedDepartment
//     )?.name;

//     const selectedProvinceName = provinces.find(
//       (province) => province.code === formData.province
//     )?.name;

//     const selectedMunicipalityName = municipalities.find(
//       (municipality) => municipality.code === formData.municipality
//     )?.name;

//     const updatedFormData = {
//       ...formData,
//       campus: selectedCampusName, // Replace selectedCampus ID with the name
//       department: selectedDepartmentName,
//       province: selectedProvinceName, // Replace selectedDepartment ID with the name
//       municipality: selectedMunicipalityName,
//     };

//     try {
//       // Send POST request to your backend
//       const response = await axios.post(
//         "${process.env.BSU_SMS_2024}/api/signup",
//         updatedFormData
//       );

//       setMessage(response.data.message || "Signup successful!");
//       alert("Your account is completely Set up.");
//       setSuccess(true);

//       // Clear the form (optional)
//       setFormData({
//         lastName: "",
//         firstName: "",
//         middleName: "",
//         age: "",
//         sex: "",
//         civilStatus: "",
//         province: "",
//         municipality: "",
//         barangay: "",
//         campus: "", // Clear the selected campus
//         department: "",
//         program: "",
//         yearLevel: "",
//         semester: "",
//         srCode: "",
//         gwa: "",
//         status: "NOT REGISTERED",
//         // email: "",
//         // password: "",
//       });
//     } catch (error) {
//       if (error.response) {
//         setMessage(
//           error.response.data.error || "An error occurred during signup"
//         );
//       } else {
//         setMessage("Error connecting to the server");
//       }
//       setSuccess(false);
//     }
//   };

//   return (
//     <Modal open={open} onClose={onClose} closeAfterTransition>
//       <Box sx={modalStyle}>
//         <Stepper activeStep={activeStep}>
//           {steps.map((label, index) => {
//             const stepProps = {};
//             const labelProps = {};
//             // if (isStepOptional(index)) {
//             //   labelProps.optional = (
//             //     <Typography variant="caption">Optional</Typography>
//             //   );
//             // }
//             if (isStepSkipped(index)) {
//               stepProps.completed = false;
//             }
//             return (
//               <Step key={label} {...stepProps}>
//                 <StepLabel {...labelProps}>{label}</StepLabel>
//               </Step>
//             );
//           })}
//         </Stepper>

//         {activeStep === steps.length ? (
//           <React.Fragment>
//             <Typography sx={{ mt: 2, mb: 1 }}>
//               {success
//                 ? "All steps completed - Signup Successful!"
//                 : "All steps completed"}
//             </Typography>

//             {/* Display the message here */}
//             <Typography color={success ? "green" : "red"}>{message}</Typography>

//             <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
//               <Box sx={{ flex: "1 1 auto" }} />
//               <Button onClick={handleReset}>Reset</Button>
//             </Box>
//           </React.Fragment>
//         ) : (
//           <React.Fragment>
//             <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

//             {activeStep === 0 && (
//               <Box component="form" noValidate sx={{ mt: 2, mb: 2 }}>
//                 {/* First row: Last Name, First Name, Middle Name */}
//                 <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//                   <TextField
//                     label="Last Name"
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                     fullWidth
//                   />
//                   <TextField
//                     label="First Name"
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                     fullWidth
//                   />
//                   <TextField
//                     label="Middle Name"
//                     name="middleName"
//                     value={formData.middleName}
//                     onChange={handleChange}
//                     fullWidth
//                   />
//                 </Box>

//                 {/* Second row: Age, Sex, Civil Status */}
//                 <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//                   <TextField
//                     label="Age"
//                     name="age"
//                     value={formData.age}
//                     onChange={handleChange}
//                     fullWidth
//                   />
//                   <TextField
//                     label="Sex"
//                     name="sex"
//                     select
//                     value={formData.sex}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     <MenuItem value="Male">Male</MenuItem>
//                     <MenuItem value="Female">Female</MenuItem>
//                   </TextField>
//                   <TextField
//                     label="Civil Status"
//                     name="civilStatus"
//                     select
//                     value={formData.civilStatus}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     <MenuItem value="Single">Single</MenuItem>
//                     <MenuItem value="Married">Married</MenuItem>
//                     <MenuItem value="Widowed">Widowed</MenuItem>
//                     <MenuItem value="Separated">Separated</MenuItem>
//                   </TextField>
//                 </Box>

//                 {/* Address fields */}
//                 <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//                   <TextField
//                     label="Province"
//                     name="province"
//                     select
//                     value={formData.province}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     {provinces.map((province) => (
//                       <MenuItem key={province.code} value={province.code}>
//                         {province.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                   <TextField
//                     label="Municipality"
//                     name="municipality"
//                     select
//                     value={formData.municipality}
//                     onChange={handleChange}
//                     disabled={!municipalities.length} // Disable if no municipalities available
//                     fullWidth
//                   >
//                     {municipalities.map((municipality) => (
//                       <MenuItem
//                         key={municipality.code}
//                         value={municipality.code}
//                       >
//                         {municipality.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                   <TextField
//                     label="Barangay"
//                     name="barangay"
//                     select
//                     value={formData.barangay}
//                     onChange={handleChange}
//                     disabled={!barangays.length} // Disable if no barangays available
//                     fullWidth
//                   >
//                     {barangays.map((barangay) => (
//                       <MenuItem key={barangay.code} value={barangay.name}>
//                         {barangay.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Box>
//               </Box>
//             )}

//             {activeStep === 1 && (
//               <Box component="form" noValidate sx={{ mt: 2, mb: 2 }}>
//                 {/* First row: Campus, Department, Program */}
//                 <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//                   <TextField
//                     label="Campus"
//                     name="campus"
//                     select
//                     value={selectedCampus} // Ensure this is the campus_id
//                     onChange={(e) => setSelectedCampus(e.target.value)}
//                     fullWidth
//                   >
//                     {campuses.map((campus) => (
//                       <MenuItem key={campus.id} value={campus.id}>
//                         {campus.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                   <TextField
//                     label="Department"
//                     name="department"
//                     select
//                     value={selectedDepartment}
//                     onChange={(e) => setSelectedDepartment(e.target.value)}
//                     fullWidth
//                   >
//                     <MenuItem value=""></MenuItem>
//                     {departments.map((department) => (
//                       <MenuItem key={department.id} value={department.id}>
//                         {department.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                   <TextField
//                     label="Program"
//                     name="program"
//                     select
//                     value={formData.program}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     <MenuItem value=""></MenuItem> {/* Empty option */}
//                     {program.map((program) => (
//                       <MenuItem key={program.id} value={program.name}>
//                         {program.name}
//                       </MenuItem>
//                     ))}
//                   </TextField>
//                 </Box>

//                 {/* Second row: Year Level, Semester, Sr-Code, GWA */}
//                 <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//                   <TextField
//                     label="Year Level"
//                     name="yearLevel"
//                     select
//                     value={formData.yearLevel}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     <MenuItem value="FIRST YEAR">1st Year</MenuItem>
//                     <MenuItem value="SECOND YEAR">2nd Year</MenuItem>
//                     <MenuItem value="THIRD YEAR">3rd Year</MenuItem>
//                     <MenuItem value="FOURTH YEAR">4th Year</MenuItem>
//                     <MenuItem value="FIFTH YEAR">5th Year</MenuItem>
//                   </TextField>
//                   <TextField
//                     label="Semester"
//                     name="semester"
//                     select
//                     value={formData.semester}
//                     onChange={handleChange}
//                     fullWidth
//                   >
//                     <MenuItem value="FIRST SEMESTER">1st Semester</MenuItem>
//                     <MenuItem value="SECOND SEMESTER">2nd Semester</MenuItem>
//                   </TextField>
//                   <TextField
//                     label="Sr-Code"
//                     name="srCode" // The name remains unchanged for data handling
//                     value={formData.srCode}
//                     onChange={handleChange}
//                     fullWidth
//                   />
//                   <TextField
//                     label="GWA"
//                     name="gwa"
//                     type="number" // Set type to number for better input handling
//                     value={formData.gwa}
//                     onChange={handleGwaChange}
//                     error={!!message} // Show error style if message exists
//                     helperText={message} // Display the error message if out of range
//                     fullWidth
//                   />
//                 </Box>
//               </Box>
//             )}

//             <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
//               <Button
//                 color="inherit"
//                 disabled={activeStep === 0}
//                 onClick={handleBack}
//                 sx={{ mr: 1 }}
//               >
//                 Back
//               </Button>
//               <Box sx={{ flex: "1 1 auto" }} />
//               {activeStep === steps.length - 1 ? (
//                 <Button
//                   variant="contained"
//                   onClick={handleSubmit} // Call handleSubmit on Finish
//                 >
//                   Finish
//                 </Button>
//               ) : (
//                 <Button variant="contained" onClick={handleNext}>
//                   {activeStep === steps.length - 1 ? "Finish" : "Next"}
//                 </Button>
//               )}
//             </Box>
//           </React.Fragment>
//         )}
//       </Box>
//     </Modal>
//   );
// }

import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import NestedModal from "./otp.js";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const steps = ["Personal Data", "Academic Data"];
const API_BASE = "https://psgc.gitlab.io/api";

export default function HorizontalLinearStepper({ open, onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const getProvinces = async () => {
    const response = await axios.get(`${API_BASE}/provinces`);
    return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
  };

  const getMunicipalities = async (provinceCode) => {
    const response = await axios.get(
      `${API_BASE}/provinces/${provinceCode}/cities-municipalities`
    );
    return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
  };

  const getBarangays = async (municipalityCode) => {
    const response = await axios.get(
      `${API_BASE}/cities-municipalities/${municipalityCode}/barangays`
    );
    return response.data.sort((a, b) => a.name.localeCompare(b.name)); // Ensure data contains both name and code
  };

  const [openNestedModal, setOpenNestedModal] = useState(false);

  const handleOpenNestedModal = () => setOpenNestedModal(true);
  const handleCloseNestedModal = () => setOpenNestedModal(false);

  const [campuses, setCampuses] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(""); // Updated from selectedProgram to selectedDepartment
  const [program, setProgram] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  // Form state
  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    middleName: "",
    age: "",
    sex: "",
    civilStatus: "",
    province: "",
    municipality: "",
    barangay: "",
    campus: selectedCampus,
    department: selectedDepartment,
    program: "",
    srCode: "",
    // email: "",
    // password: "",
  });

  const [message, setMessage] = useState("");
  // const [emailError, setEmailError] = useState("");
  const [success, setSuccess] = useState(false); // Track success state

  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [barangays, setBarangays] = useState([]);

  // Fetch provinces on component mount
  useEffect(() => {
    const fetchProvinces = async () => {
      const data = await getProvinces();
      setProvinces(data);
    };
    fetchProvinces();
  }, []);

  // Fetch municipalities when province is selected
  useEffect(() => {
    if (formData.province) {
      const fetchMunicipalities = async () => {
        const data = await getMunicipalities(formData.province);
        setMunicipalities(data);
        setBarangays([]); // Reset barangays when province changes
        setFormData((prev) => ({ ...prev, municipality: "", barangay: "" })); // Reset form data
      };
      fetchMunicipalities();
    }
  }, [formData.province]);

  // Fetch barangays when municipality is selected
  useEffect(() => {
    if (formData.municipality) {
      const fetchBarangays = async () => {
        const data = await getBarangays(formData.municipality);
        setBarangays(data);
        setFormData((prev) => ({ ...prev, barangay: "" })); // Reset barangay when municipality changes
      };
      fetchBarangays();
    }
  }, [formData.municipality]);

  useEffect(() => {
    axios
      .get("https://bsusms.online/api/campuses")
      .then((response) => {
        // Log the entire response to inspect its structure
        console.log("Axios Response:", response);

        // Access and log the data property directly
        const campusesData = response.data;
        console.log("Fetched Campuses:", campusesData); // Log data here

        // Set campuses state
        setCampuses(campusesData);
      })
      .catch((error) => {
        console.error("There was an error fetching campuses!", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCampus) {
      console.log("Selected campus ID:", selectedCampus); // Log the selected campus ID
      axios
        .get(`https://bsusms.online/api/departments/${selectedCampus}`)
        .then((response) => {
          setDepartments(response.data);
          setSelectedDepartment(""); // Reset selected department
          setProgram([]); // Clear majors when campus changes
        })
        .catch((error) => {
          console.error("There was an error fetching departments!", error);
        });
    } else {
      setDepartments([]);
      setProgram([]);
    }
  }, [selectedCampus]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/departments"
        );
        setDepartments(response.data); // Store the list of departments in state
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      axios
        .get(`https://bsusms.online/api/program/${selectedDepartment}`)
        .then((response) => {
          setProgram(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching majors!", error);
        });
    } else {
      setProgram([]);
    }
  }, [selectedDepartment]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("Field name:", name); // Log the field name
    console.log("Field value:", value); // Log the field value

    if (name === "campus") {
      setSelectedCampus(value);
      console.log("Updated selectedCampus:", value); // Log updated campus
    } else if (name === "department") {
      setSelectedDepartment(value);
      console.log("Updated selectedDepartment:", value); // Log updated department
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    console.log("Selected Campus:", selectedCampus);
    console.log("Selected Department:", selectedDepartment);
  }, [selectedCampus, selectedDepartment]);

  const handleNext = () => {
    // Validate form before allowing to proceed
    if (activeStep === 0) {
      const { lastName, firstName, middleName, age, sex, civilStatus } =
        formData;
      if (
        !lastName ||
        !firstName ||
        !middleName ||
        !age ||
        !sex ||
        !civilStatus
      ) {
        alert("Please complete all fields before proceeding.");
        return;
      }
    } else if (activeStep === 1) {
      const { program, srCode } = formData;
      if (
        // !campus ||
        // !department ||
        !program ||
        !srCode
      ) {
        alert("Please complete all fields before proceeding.");
        return;
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleReset = () => setActiveStep(0);

  // const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 800,
    width: isMobile ? "90%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: "10px",
  };

  const handleGwaChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, gwa: value }));

    // Validate that the GWA is between 1.0 and 5.0
    if (value < 1 || value > 5) {
      setMessage("GWA must be between 1.0 and 5.0");
    } else {
      setMessage(""); // Clear the message if valid
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Find the campus name from the selectedCampus ID
    const selectedCampusName = campuses.find(
      (campus) => campus.id === selectedCampus
    )?.name;
  
    const selectedDepartmentName = departments.find(
      (department) => department.id === selectedDepartment
    )?.name;
  
    const selectedProvinceName = provinces.find(
      (province) => province.code === formData.province
    )?.name;
  
    const selectedMunicipalityName = municipalities.find(
      (municipality) => municipality.code === formData.municipality
    )?.name;
  
    const updatedFormData = {
      ...formData,
      campus: selectedCampusName, // Replace selectedCampus ID with the name
      department: selectedDepartmentName,
      province: selectedProvinceName, // Replace selectedProvince ID with the name
      municipality: selectedMunicipalityName,
    };
  
    try {
      // Send POST request to your backend
      const response = await axios.post(
        "https://bsusms.online/api/signup",
        updatedFormData
      );
  
      // Store the email in sessionStorage
      const email = response.data.email; // Assuming email is returned in response data
      sessionStorage.setItem("email", email);
  
      setMessage(response.data.message || "Signup successful!");
      // alert("Your account is completely set up.");
      // setSuccess(true);
  
      // Clear the form after success
      setFormData({
        lastName: "",
        firstName: "",
        middleName: "",
        age: "",
        sex: "",
        civilStatus: "",
        province: "",
        municipality: "",
        barangay: "",
        campus: "", // Clear the selected campus
        department: "",
        program: "",
        srCode: "",
        status: "NOT REGISTERED",
      });
  
      // Close the modal
      onClose();
  
      // open OTP modal
      handleOpenNestedModal();
    } catch (error) {
      if (error.response) {
        setMessage(
          error.response.data.error || "An error occurred during signup"
        );
      } else {
        setMessage("Error connecting to the server");
      }
      setSuccess(false);
    }
  };  

  return (
    <>
      <Modal open={open} onClose={onClose} closeAfterTransition>
        <Box sx={modalStyle}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              // if (isStepOptional(index)) {
              //   labelProps.optional = (
              //     <Typography variant="caption">Optional</Typography>
              //   );
              // }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {success
                  ? "All steps completed - Signup Successful!"
                  : "All steps completed"}
              </Typography>

              {/* Display the message here */}
              <Typography color={success ? "green" : "red"}>
                {message}
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography>

              {activeStep === 0 && (
                <Box component="form" noValidate sx={{ mt: 2, mb: 2 }}>
                  {/* First row: Last Name, First Name, Middle Name */}
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                    />
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                    />
                    <TextField
                      label="Middle Name"
                      name="middleName"
                      value={formData.middleName}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Box>

                  {/* Second row: Age, Sex, Civil Status */}
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <TextField
  label="Age"
  name="age"
  value={formData.age}
  onChange={(e) => {
    // Only allow numbers
    const newValue = e.target.value.replace(/\D/g, '');
    handleChange({ target: { name: 'age', value: newValue } });
  }}
  fullWidth
  type="text" // Keep as text for better handling of non-numeric input
/>
                    <TextField
                      label="Sex"
                      name="sex"
                      select
                      value={formData.sex}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                    <TextField
                      label="Civil Status"
                      name="civilStatus"
                      select
                      value={formData.civilStatus}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="Single">Single</MenuItem>
                      <MenuItem value="Married">Married</MenuItem>
                      <MenuItem value="Widowed">Widowed</MenuItem>
                      <MenuItem value="Separated">Separated</MenuItem>
                    </TextField>
                  </Box>

                  {/* Address fields */}
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <TextField
                      label="Province"
                      name="province"
                      select
                      value={formData.province}
                      onChange={handleChange}
                      fullWidth
                    >
                      {provinces.map((province) => (
                        <MenuItem key={province.code} value={province.code}>
                          {province.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Municipality"
                      name="municipality"
                      select
                      value={formData.municipality}
                      onChange={handleChange}
                      disabled={!municipalities.length} // Disable if no municipalities available
                      fullWidth
                    >
                      {municipalities.map((municipality) => (
                        <MenuItem
                          key={municipality.code}
                          value={municipality.code}
                        >
                          {municipality.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Barangay"
                      name="barangay"
                      select
                      value={formData.barangay}
                      onChange={handleChange}
                      disabled={!barangays.length} // Disable if no barangays available
                      fullWidth
                    >
                      {barangays.map((barangay) => (
                        <MenuItem key={barangay.code} value={barangay.name}>
                          {barangay.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </Box>
              )}

              {activeStep === 1 && (
                <Box component="form" noValidate sx={{ mt: 2, mb: 2 }}>
                  {/* First row: Campus, Department, Program */}
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <TextField
                      label="Campus"
                      name="campus"
                      select
                      value={selectedCampus} // Ensure this is the campus_id
                      onChange={(e) => setSelectedCampus(e.target.value)}
                      fullWidth
                    >
                      {campuses.map((campus) => (
                        <MenuItem key={campus.id} value={campus.id}>
                          {campus.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Department"
                      name="department"
                      select
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      {departments.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Program"
                      name="program"
                      select
                      value={formData.program}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem> {/* Empty option */}
                      {program.map((program) => (
                        <MenuItem key={program.id} value={program.name}>
                          {program.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  {/* Second row: Year Level, Semester, Sr-Code, GWA */}
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                   
                  
                    <TextField
                      label="Sr-Code"
                      name="srCode" // The name remains unchanged for data handling
                      value={formData.srCode}
                      onChange={handleChange}
                      fullWidth
                    />
                    

                    {/* <Button variant="outlined" onClick={handleOpenNestedModal}>
                      Open Nested Modal
                    </Button> */}
                  </Box>
                </Box>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    onClick={handleSubmit} // Call handleSubmit on Finish
                  >
                    Finish
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Modal>
      <NestedModal
        open={openNestedModal}
        handleClose={handleCloseNestedModal}
      />
    </>
  );
}
