import React from 'react';
import Content from "./content";
import Admindocuments from "./admindocuments.js"
import Carousel from "./demotable.js";

const Dashboard = () => {
  return (
    <>
      <Content />
      <Admindocuments/>
      <Carousel/>
    </>
  );
}

export default Dashboard;
