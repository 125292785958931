import React, { useState, useEffect } from "react";
import "./dashboard_d.css";
import Header from "../components/usercomponents/header.js";
import Advisory from "../components/usercomponents/advisory.js";
import Documents from "../components/usercomponents/documents.js";
import Carousel from "../components/usercomponents/userCarousel.js";
import Top from "../components/usercomponents/top.js";
import Tab from "../components/usercomponents/tab.js";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Snackbar, // Import Snackbar
  Alert, // Import Alert for Snackbar
} from "@mui/material";
import axios from "axios";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState(""); // Initialize message state
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  const [formData, setFormData] = useState({
    yearLevel: "",
    semester: "",
    gwa: "",
    familyIncome: "",
    otherIncomeSource: "",
    otherIncomeAmount: "",
  });

  // useEffect(() => {
  //   const isFirstLogin = !localStorage.getItem("firstLogin");
  //   if (isFirstLogin) {
  //     setIsModalOpen(true);
  //     localStorage.setItem("firstLogin", "true");
  //   }

  //   const interval = setInterval(() => {
  //     setIsModalOpen(true);
  //   }, 30000); // 30 seconds

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const checkPopupStatus = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get("https://bsusms.online/api/checkPopup", {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        // Open modal if popupShown is 0
        if (response.data.popupShown === 0) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error("Error fetching popup status:", error);
      }
    };

    checkPopupStatus();
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleCloseModal();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    // Reset the other income amount if 'None' is selected
    if (name === "otherIncomeSource" && value === "None") {
      setFormData((prevData) => ({ ...prevData, otherIncomeAmount: "" }));
    }
  };

  const handleGwaChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, gwa: value }));

    // Validate that the GWA is between 1.0 and 5.0
    if (value < 1 || value > 5) {
      setMessage("GWA must be between 1.0 and 5.0");
    } else {
      setMessage(""); // Clear the message if valid
    }
  };

  // const token = localStorage.getItem("token");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(
        "https://bsusms.online/api/popup",
        { ...formData, popupShown: 1 },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSnackbarMessage("Data updated successfully!");
      setSnackbarOpen(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      setSnackbarMessage("Error submitting data.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <>
      <Top />
      <Header />
      <Advisory />
      <Documents />
      <Carousel/>
      <Tab />

      {/* Popup Modal */}
      <Dialog open={isModalOpen} onClose={handleDialogClose}>
        <DialogTitle>Welcome to the Dashboard!</DialogTitle>
        <DialogContent>
          <p>
            This is your first login. Please fill out your information below:
          </p>

          <form onSubmit={handleSubmit}>
            {/* Academic Data */}
            <div className="academic-data">
              <h4>Academic Data</h4>
              <TextField
                label="Year Level"
                name="yearLevel"
                select
                value={formData.yearLevel}
                onChange={handleChange}
                fullWidth
                required
                sx={{ mb: 2 }} // Add margin bottom for spacing
              >
                <MenuItem value="FIRST YEAR">1st Year</MenuItem>
                <MenuItem value="SECOND YEAR">2nd Year</MenuItem>
                <MenuItem value="THIRD YEAR">3rd Year</MenuItem>
                <MenuItem value="FOURTH YEAR">4th Year</MenuItem>
                <MenuItem value="FIFTH YEAR">5th Year</MenuItem>
              </TextField>
              <TextField
                label="Semester"
                name="semester"
                select
                value={formData.semester}
                onChange={handleChange}
                fullWidth
                required
                sx={{ mb: 2 }} // Add margin bottom for spacing
              >
                <MenuItem value="FIRST SEMESTER">1st Semester</MenuItem>
                <MenuItem value="SECOND SEMESTER">2nd Semester</MenuItem>
              </TextField>
              <TextField
                label="GWA"
                name="gwa"
                type="number"
                value={formData.gwa}
                onChange={handleGwaChange}
                error={!!message} // Show error style if message exists
                helperText={message} // Display the error message if out of range
                fullWidth
                required
                sx={{ mb: 2 }} // Add margin bottom for spacing
              />
            </div>

            {/* Family Data */}
            <div className="family-data">
              <h4>Family Data</h4>
              <TextField
                select
                label="Estimated Family Monthly Income"
                name="familyIncome"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.familyIncome}
                onChange={handleChange}
                required
              >
                <MenuItem value="Below 10,957">Below 10,957</MenuItem>
                <MenuItem value="10,957 - 21,194">10,957 - 21,194</MenuItem>
                <MenuItem value="21,194 - 43,829">21,194 - 43,829</MenuItem>
                <MenuItem value="43,829 - 76,669">43,829 - 76,669</MenuItem>
                <MenuItem value="76,669 - 131,484">76,669 - 131,484</MenuItem>
                <MenuItem value="131,484 - 219,140">131,484 - 219,140</MenuItem>
                <MenuItem value="Above 219,140">Above 219,140</MenuItem>
              </TextField>
              <TextField
                select
                label="Other Source of Income"
                name="otherIncomeSource"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.otherIncomeSource}
                onChange={handleChange}
                required
              >
                <MenuItem value="None">None</MenuItem>
                <MenuItem value="Support from Relatives">
                  Support from Relatives
                </MenuItem>
                <MenuItem value="Self-employed / Owns a Business">
                  Self-employed / Owns a Business
                </MenuItem>
                <MenuItem value="Support from Government">
                  Support from Government
                </MenuItem>
                <MenuItem value="Support from Non-government Institution">
                  Support from Non-government Institution
                </MenuItem>
                <MenuItem value="Support from Siblings">
                  Support from Siblings
                </MenuItem>
              </TextField>
              <TextField
                label="Estimated Amount per Month"
                name="otherIncomeAmount"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.otherIncomeAmount}
                onChange={handleChange}
                disabled={formData.otherIncomeSource === "None"}
                required
              />
            </div>
            <DialogActions>
              <Button type="submit" color="primary">
                Submit
              </Button>
              {/* <Button onClick={handleCloseModal}>Close</Button> */}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Snackbar for Success Message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Navbar;
