import React, { useState, useEffect } from 'react';

const Documents = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch('https://bsusms.online/api/documents');
        const data = await response.json();
        setDocuments(data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };
    fetchDocuments();
  }, []);

  return (
    <div className="card-deck">
      <div className="card border-info shadow-sm" style={{ margin: '10px' }}>
        <div className="card-body">
          <h5 className="card-title text-primary">
            <i className="far fa-file-alt"></i> Download Documents
          </h5>
          <ul>
            {documents.map((doc) => (
              <li key={doc.id}>
                <a href={`https://bsusms.online/api/uploads/${doc.filename}`} download>
                  {doc.originalname}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Documents;
