// import React, { useState, useEffect } from "react";
// import "./try.css";

// const Try = () => {
//   const [file, setFile] = useState(null);
//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   useEffect(() => {
//     const fetchUploadedFiles = async () => {
//       try {
//         const response = await fetch("${process.env.BSU_SMS_2024}/api/uploads");
//         const data = await response.json();
//         setUploadedFiles(data);
//       } catch (error) {
//         console.error("Error fetching uploaded files:", error);
//       }
//     };
//     fetchUploadedFiles();
//   }, []);

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleUpload = async () => {
//     if (!file) return;

//     const formData = new FormData();
//     formData.append("document", file);

//     try {
//       const response = await fetch("${process.env.BSU_SMS_2024}/api/upload", {
//         method: "POST",
//         body: formData,
//       });
//       const result = await response.json();
//       alert(result.message);
//       // Refresh the list of uploaded files
//       const fetchResponse = await fetch("${process.env.BSU_SMS_2024}/api/uploads");
//       const data = await fetchResponse.json();
//       setUploadedFiles(data);
//     } catch (error) {
//       console.error("Error uploading document:", error);
//       alert("Failed to upload document");
//     }
//   };

//   const handleDelete = async (filename) => {
//     if (window.confirm(`Are you sure you want to delete ${filename}?`)) {
//       try {
//         const response = await fetch(
//           `${process.env.BSU_SMS_2024}/api/uploads/${filename}`,
//           {
//             method: "DELETE",
//           }
//         );
//         const result = await response.json();
//         alert(result.message);
//         // Refresh the list of uploaded files
//         const fetchResponse = await fetch("${process.env.BSU_SMS_2024}/api/uploads");
//         const data = await fetchResponse.json();
//         setUploadedFiles(data);
//       } catch (error) {
//         console.error("Error deleting document:", error);
//         alert("Failed to delete document");
//       }
//     }
//   };

//   return (
//     <div className="form">
//       <span className="form-title">Upload Application Document</span>
//       <p className="form-paragraph">Files should be in docs or pdf format</p>
//       <div className="dcontainer">
//         <input
//           type="file"
//           onChange={handleFileChange}
//           required
//           id="file-input"
//         />
//         <button className="btnd" onClick={handleUpload}>
//           Upload Document
//         </button>
//         <span className="up-title">Uploaded List</span>

//         <ul>
//           {uploadedFiles.map((filename) => (
//             <li key={filename}>
//               <a
//                 href={`${process.env.BSU_SMS_2024}/uploads/${file.originalname}`}
//                 download
//                 className="filename"
//               >
//                 {file.originalname}
//               </a>
//               <button
//                 className="tbutton"
//                 onClick={() => handleDelete(filename)}
//               >
//                 Delete
//               </button>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Try;

import React, { useState, useEffect } from "react";
import "./try.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Input from "@mui/material";

const Try = () => {
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await fetch("https://bsusms.online/api/uploads");
        const data = await response.json();
        setUploadedFiles(data);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
      }
    };
    fetchUploadedFiles();
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("document", file);

    try {
      const response = await fetch("https://bsusms.online/api/upload", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      alert(result.message);
      // Refresh the list of uploaded files
      const fetchResponse = await fetch("https://bsusms.online/api/uploads");
      const data = await fetchResponse.json();
      setUploadedFiles(data);
    } catch (error) {
      console.error("Error uploading document:", error);
      alert("Failed to upload document");
    }
  };

  const handleDelete = async (originalname) => {
    if (window.confirm(`Are you sure you want to delete ${originalname}?`)) {
      try {
        const response = await fetch(
          `https://bsusms.online/api/uploads/${originalname}`,
          {
            method: "DELETE",
          }
        );
        const result = await response.json();
        alert(result.message);
        // Refresh the list of uploaded files
        const fetchResponse = await fetch("https://bsusms.online/api/uploads");
        const data = await fetchResponse.json();
        setUploadedFiles(data);
      } catch (error) {
        console.error("Error deleting document:", error);
        alert("Failed to delete document");
      }
    }
  };

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="h6" component="div">
            Scholarship Application Form
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
            Files should be in docs or pdf format
          </Typography>

          <div className="dcontainer">
            <input
              type="file"
              onChange={handleFileChange}
              required
              id="file-input"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              sx={{ mt: 2 }}
            >
              UPLOAD FILE
            </Button>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Edit Announcement
            </Typography>

            <ul>
              {uploadedFiles.map((file) => (
                <li key={file.id}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    <a
                      href={`https://bsusms.online/api/uploads/${file.originalname}`}
                      download
                      className="originalname"
                    >
                      {file.originalname}
                    </a>
                  </Typography>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(file.originalname)}
                    sx={{ mt: 2 }}
                  >
                    Delete
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Try;
