import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, CardBody, CardTitle, CardSubtitle, Spinner } from "reactstrap";
import jsPDF from "jspdf";
import './applicationStatus.css';

const SARequirementStatus = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("https://bsusms.online/api/appStatus", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        setError("Wait until your application has been approved.");
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const generatePDF = () => {
    const doc = new jsPDF();
    const logoUrl = `${window.location.origin}/images/bsu_logo.png`;
  
    // Setting dimensions for the image and placing it on the right
    const imgWidth = 26;
    const imgHeight = 26;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const logoXPosition = pageWidth - imgWidth - 152; // Position logo 152 units from the right edge
    const logoYPosition = pageHeight - imgHeight - 250; // Position logo 250 units from the bottom
  
    doc.addImage(logoUrl, "PNG", logoXPosition, logoYPosition, imgWidth, imgHeight);
  
    // Adding header text
    doc.setFontSize(10);
    doc.text("Reference No. ", 15, 8);
    doc.text("Effectivity: ", pageWidth / 2, 8, { align: "center" });
    doc.text("Revision No. ", pageWidth - 15, 8, { align: "right" });
  
    // Main title
    doc.setFontSize(12);
    doc.setFont("times new roman", "bold");
    doc.text("Republic of the Philippines", pageWidth / 2, 25, { align: "center" });
    doc.text("BATANGAS STATE UNIVERSITY", pageWidth / 2, 30, { align: "center" });
    doc.setFont("times new roman", "normal");
    doc.setTextColor(255, 0, 0); // Set text color to red
    doc.text("The National Engineering University", pageWidth / 2, 35, { align: "center" });
    doc.setTextColor(0, 0, 0);
    doc.text("JPLPC-Malvar Campus", pageWidth / 2, 40, { align: "center" });
    doc.text("G. Leviste St., Poblacion, Malvar, Batangas", pageWidth / 2, 45, { align: "center" });
  
    // Horizontal line
    doc.line(15, 50, pageWidth - 15, 50);
  
    // Adding additional information
    // doc.setFontSize(10);
    // doc.setFont("times new roman", "bold");
    // doc.text(`${userData.department}`, pageWidth / 2, 55, { align: "center" });
    // doc.text("SECOND, 2023-2024", pageWidth / 2, 60, { align: "center" });
    doc.setFontSize(15);
    doc.setFont("times new roman", "bold");
    doc.text("CERTIFICATE OF SCHOLARSHIP", pageWidth / 2, 70, { align: "center" });
  
    // Scholarship details
    doc.setFontSize(12);
    doc.setFont("times new roman", "normal");
    doc.text("Scholarship Name: ", 20, 90);
    doc.text("Recipient: ", 20, 97);
    doc.text("SR-Code: ", 20, 104);
    doc.text("Program: ", 20, 111);
    doc.text("Department: ", 20, 118);
    doc.text("Campus: ", 20, 125);

    doc.setFont("times new roman", "bold");
    doc.text(userData.scholar, 57, 90); 
    doc.text(`${userData.lastName}, ${userData.firstName} ${userData.middleName}`, 57, 97);
    doc.text(userData.srCode, 57, 104);
    doc.text(userData.program, 57, 111); 
    doc.text(userData.department, 57, 118);
    doc.text(userData.campus, 57, 125);
  
    // doc.text("Congratulations on your scholarship!", 20, 130);
  
    // Generate Blob URL and open in new tab
    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");

    // Save the PDF with a default filename
    // doc.save(`Scholarship_Certificate_${userData.srCode}_${userData.scholar}.pdf`);
  };

  return (
    <Card className="SA-card text-center">
      {/* Download Icon Button */}
      <Button
        color="primary"
        className="download-button"
        onClick={(e) => {
          e.stopPropagation(); // Prevents the card's onClick from triggering
          generatePDF();
        }}
      >
        <i className="fa fa-download fa-inverse"></i> {/* Font Awesome download icon */}
      </Button>
      
      <CardBody>
        {loading ? (
          <Spinner color="primary" />
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <>
            <CardTitle tag="h5" className="SA-card-title">
              {userData.scholar}
            </CardTitle>
            <CardSubtitle className="SA-card-subtitle mb-2">
              Email: {userData.email}
            </CardSubtitle>
            <p>Program: {userData.program}</p>
            <p>Campus: {userData.campus}</p>
            <p className="SA-congratulations">
              Congratulations! You are eligible for {userData.scholar} Scholarship.
            </p>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default SARequirementStatus;
