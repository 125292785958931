// import React, { useState, useEffect } from 'react';
// import { Container, Alert, Button, Table, Spinner } from 'react-bootstrap';
// import axios from 'axios';

// const Home = () => {
//   const [files, setFiles] = useState({
//     application_form: null,
//     cor: null,
//     grade: null,
//   });

//   const [uploading, setUploading] = useState(false);
//   const [uploadSuccess, setUploadSuccess] = useState(null);
//   const [uploadError, setUploadError] = useState(null);
//   const [hasUploaded, setHasUploaded] = useState(false);
//   const [existingFiles, setExistingFiles] = useState({
//     application_form: null,
//     cor: null,
//     grade: null,
//   });

//   useEffect(() => {
//     const fetchUploadStatus = async () => {
//       const token = localStorage.getItem('authToken');
//       if (!token) {
//         console.log('No auth token found');
//         return;
//       }
//       try {
//         const response = await axios.get('${process.env.BSU_SMS_2024}/api/upload/status', {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//           withCredentials: true,
//         });
//         const { hasUploaded, files } = response.data;
//         setHasUploaded(hasUploaded);
//         setExistingFiles(files);
//       } catch (error) {
//         console.error('Error fetching upload status:', error);
//         // Optionally handle errors
//       }
//     };

//     fetchUploadStatus();
//   }, []);

//   const handleFileChange = (e) => {
//     const { name, files: selectedFiles } = e.target;
//     if (selectedFiles.length > 0) {
//       setFiles((prev) => ({
//         ...prev,
//         [name]: selectedFiles[0],
//       }));
//     }
//   };

//   // Helper function to extract original filename
//   const getOriginalFileName = (filePath) => {
//     if (!filePath) return '';
//     const filename = filePath.split('/').pop(); // e.g., "1633036800000-originalname.pdf"
//     const parts = filename.split(/-(.+)/); // Split at the first hyphen
//     return parts.length > 1 ? parts[1] : filename; // Returns "originalname.pdf"
//   };

//   const handleSubmit = async () => {
//     const formData = new FormData();
//     if (files.application_form) {
//       formData.append('application_form', files.application_form);
//     }
//     if (files.cor) {
//       formData.append('cor', files.cor);
//     }
//     if (files.grade) {
//       formData.append('grade', files.grade);
//     }

//     console.log('Submitting files:', {
//       application_form: files.application_form,
//       cor: files.cor,
//       grade: files.grade,
//     }); // Debug log

//     const token = localStorage.getItem('authToken'); // Ensure 'authToken' is correct

//     if (!token) {
//       alert('Authentication token not found. Please log in.');
//       return;
//     }

//     try {
//       setUploading(true);
//       const response = await axios.post('${process.env.BSU_SMS_2024}/api/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${token}`, // Include the token in headers
//         },
//         withCredentials: true, // Include cookies if needed
//       });
//       alert(response.data.message);
//       setUploadSuccess(response.data.message);
//       setHasUploaded(true);
//       setExistingFiles(response.data.files);
//       // Optionally clear files state
//       setFiles({
//         application_form: null,
//         cor: null,
//         grade: null,
//       });
//     } catch (error) {
//       console.error('Error uploading files:', error);
//       if (error.response) {
//         alert(`Error: ${error.response.data.message || 'Error uploading files'}`);
//       } else {
//         alert('Error uploading files. Please try again.');
//       }
//       setUploadError(error.response?.data?.message || 'Error uploading files');
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <Container>
//       <div className="page-header d-flex justify-content-between">
//         <h3 className="mb-0">Scholarship Requirements</h3>
//       </div>
//       <Alert variant="info">
//         <strong>Note:</strong> Ensure that the application form you submit aligns with your selected scholarship program.
//       </Alert>

//       {hasUploaded && (
//         <Alert variant="success">
//           You have already submitted your scholarship requirements.
//         </Alert>
//       )}

//       {!hasUploaded && (
//         <div className="d-flex flex-column mb-50">
//           <Table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">#</th>
//                 <th scope="col">Requirements</th>
//                 <th scope="col">Upload</th>
//                 <th scope="col">Selected File</th> {/* New Column for User Feedback */}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <th scope="row">1</th>
//                 <td>Application Form</td>
//                 <td>
//                   <input
//                     type="file"
//                     name="application_form"
//                     onChange={handleFileChange}
//                     accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
//                   />
//                 </td>
//                 <td>{files.application_form ? files.application_form.name : 'No file selected'}</td>
//               </tr>
//               <tr>
//                 <th scope="row">2</th>
//                 <td>COR</td>
//                 <td>
//                   <input
//                     type="file"
//                     name="cor"
//                     onChange={handleFileChange}
//                     accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
//                   />
//                 </td>
//                 <td>{files.cor ? files.cor.name : 'No file selected'}</td>
//               </tr>
//               <tr>
//                 <th scope="row">3</th>
//                 <td>Grade</td>
//                 <td>
//                   <input
//                     type="file"
//                     name="grade"
//                     onChange={handleFileChange}
//                     accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
//                   />
//                 </td>
//                 <td>{files.grade ? files.grade.name : 'No file selected'}</td>
//               </tr>
//             </tbody>
//           </Table>
//           <Button 
//             onClick={handleSubmit} 
//             className="btn btn-primary"
//             disabled={uploading}
//           >
//             {uploading ? (
//               <>
//                 <Spinner
//                   as="span"
//                   animation="border"
//                   size="sm"
//                   role="status"
//                   aria-hidden="true"
//                 /> Uploading...
//               </>
//             ) : (
//               'Submit All'
//             )}
//           </Button>
//         </div>
//       )}

//       {hasUploaded && existingFiles.application_form && (
//         <div>
//           <h5>Uploaded Files:</h5>
//           <ul>
//             <li>
//               Application Form: <a href={`${process.env.BSU_SMS_2024}/${existingFiles.application_form}`} target="_blank" rel="noopener noreferrer">{getOriginalFileName(existingFiles.application_form)}</a>
//             </li>
//             <li>
//               COR: <a href={`${process.env.BSU_SMS_2024}/${existingFiles.cor}`} target="_blank" rel="noopener noreferrer">{getOriginalFileName(existingFiles.cor)}</a>
//             </li>
//             <li>
//               Grade: <a href={`${process.env.BSU_SMS_2024}/${existingFiles.grade}`} target="_blank" rel="noopener noreferrer">{getOriginalFileName(existingFiles.grade)}</a>
//             </li>
//           </ul>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default Home;

import React, { useState, useEffect } from 'react';
import { Container, Alert, Button, Table, Spinner } from 'react-bootstrap';
import axios from 'axios';


const Home = () => {
  // State to manage selected files
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);


  const [files, setFiles] = useState({
    application_form: null,
    cor: null,
    grade: null,
  });


  // State to manage upload statuses
  const [uploadStatus, setUploadStatus] = useState({
    application_form: {
      uploading: false,
      uploaded: false,
      filePath: null,
      error: null,
    },
    cor: {
      uploading: false,
      uploaded: false,
      filePath: null,
      error: null,
    },
    grade: {
      uploading: false,
      uploaded: false,
      filePath: null,
      error: null,
    },
  });

  // Fetch upload status on component mount




  useEffect(() => {
    const fetchUserMessage = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.log('No auth token found');
        return;
      }

      try {
        const response = await axios.get('https://bsusms.online/api/message', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        });
        setMessage(response.data.message); // Assuming the response has a message field
      } catch (err) {
        console.error(err);
        setError('Failed to fetch message');
      }
    };

    fetchUserMessage();
  }, []); // Fetch message when the component mounts


///////////////////////////////////////////////////////////////////////////


const userInfo = localStorage.getItem('userInfo');

if (userInfo) {
  const userObject = JSON.parse(userInfo);  // Parse the JSON string
  const userId = userObject.id;  // Access the 'id' field
  localStorage.setItem('userId', userId); // Storing the userId separately

  // console.log('User ID:', userId);
} else {
  console.log('User info not found in localStorage.');
}


  // Handle file selection
  const handleFileChange = (e) => {
    const { name, files: selectedFiles } = e.target;
    if (selectedFiles.length > 0) {
      setFiles((prev) => ({
        ...prev,
        [name]: selectedFiles[0],
      }));
    }
  };

  // Helper function to extract original filename
  const getOriginalFileName = (filePath) => {
    if (!filePath) return '';
    const filename = filePath.split('/').pop(); // e.g., "1633036800000-originalname.pdf"
    const hyphenIndex = filename.indexOf('-');
    return hyphenIndex !== -1 ? filename.substring(hyphenIndex + 1) : filename;
  };

  // Handle individual file upload
  const handleUpload = async (fileType) => {
    const token = localStorage.getItem('authToken');
    const userId = localStorage.getItem('userId'); // Make sure userId is stored in localStorage
  
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }
  
    const file = files[fileType];
    if (!file) {
      alert(`Please select a ${fileType === 'application_form' ? 'Application Form' : fileType.toUpperCase()} file to upload.`);
      return;
    }
  
    // Update status to pending before the file upload
    const statusField = `${fileType}_status`; // Construct the status field dynamically
  
    try {
      // Update status first
      await axios.post(
        'https://bsusms.online/api/updateStatus', // Update status route
        { userId, statusField, status: 'pending' },  // Send userId and statusField to backend
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
  
      alert(`${fileType.toUpperCase()} status updated to pending.`);
  
      const formData = new FormData();
      formData.append(fileType, file);
  
      setUploadStatus((prev) => ({
        ...prev,
        [fileType]: {
          ...prev[fileType],
          uploading: true,
          error: null,
        },
      }));
  
      // Now proceed with the file upload
      const response = await axios.post(`https://bsusms.online/api/upload/${fileType}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
        withCredentials: true,
      });
  
      setUploadStatus((prev) => ({
        ...prev,
        [fileType]: {
          ...prev[fileType],
          uploading: false,
          uploaded: true,
          filePath: response.data.filePath,
          error: null,
        },
      }));
  
      // Clear the selected file after successful upload
      setFiles((prev) => ({
        ...prev,
        [fileType]: null,
      }));
  
      alert(response.data.message);
  
    } catch (error) {
      console.error(`Error uploading ${fileType}:`, error);
      const errorMessage = error.response?.data?.message || `Error uploading ${fileType}. Please try again.`;
      setUploadStatus((prev) => ({
        ...prev,
        [fileType]: {
          ...prev[fileType],
          uploading: false,
          uploaded: false,
          error: errorMessage,
        },
      }));
      alert(errorMessage);
    }
  };
  

  useEffect(() => {
    const fetchUploadStatus = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.log('No auth token found');
        return;
      }
      try {
        const response = await axios.get('https://bsusms.online/api/upload/status', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,

        });

        
        console.log('Upload Status Response:', response.data); // Debug log

        
        const { files } = response.data;
        setFiles(files);
        console.log(files.grade_status)
        console.log(files.cor_status);
        console.log(files.application_form_status);

        setUploadStatus({ 
          application_form: {
            uploading: false,
            uploaded: !!files.application_form,
            filePath: files.application_form,
            error: null,
          },
          cor: {
            uploading: false,
            uploaded: !!files.cor,
            filePath: files.cor,
            error: null,
          },
          grade: {
            uploading: false,
            uploaded: !!files.grade,
            filePath: files.grade,
            error: null,
          },
        });

      

       
      } catch (error) {
        console.error('Error fetching upload status:', error);
        // Optionally handle errors (e.g., show a notification)
      }


      
    };

    fetchUploadStatus();
  }, []);

  

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'green'; // Approved will be green
      case 'denied':
        return 'red';   // Denied will be red
      case 'pending':
        return 'orange'; // Pending will be orange
      default:
        return 'black';  // Default fallback color
    }
  };

  return (
    <Container  style={{ marginBottom: '50px' }}>
      <div className="page-header d-flex justify-content-between">
        <h3 className="mb-0">Scholarship Requirements</h3>
      </div>


      <Alert variant="info">
        <strong>Note:</strong> Ensure that the application form you submit aligns with your selected scholarship program.
      </Alert>

     {error && <Alert severity="error">{error}</Alert>}
     {message && <Alert severity="info" variant='danger'><strong>Note:</strong>{message}</Alert>}


      <div className="d-flex flex-column mb-50">
        <Table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Requirements</th>
              <th scope="col">Upload</th>
              <th scope="col">Selected File</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Application Form Row */}
            <tr>
              <th scope="row">1</th>
              <td>Application Form</td>
              <td>
                <input
                  type="file"
                  name="application_form"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  disabled={uploadStatus.application_form.uploaded}
                />
              </td>
              <td>
                {uploadStatus.application_form.uploaded
                  ? getOriginalFileName(uploadStatus.application_form.filePath)
                  : (files.application_form ? files.application_form.name : 'No file selected')}
              </td>
              <td>
                {uploadStatus.application_form.uploaded ? (
                  <span className="text-success">Uploaded</span>
                ) : (
                  <Button 
                    variant="primary" 
                    onClick={() => handleUpload('application_form')}
                    disabled={uploadStatus.application_form.uploading || !files.application_form}
                  >
                    {uploadStatus.application_form.uploading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Uploading...
                      </>
                    ) : (
                      'Upload'
                    )}
                  </Button>
                )}
                {uploadStatus.application_form.error && (
                  <div className="text-danger mt-2">{uploadStatus.application_form.error}</div>
                )}
              </td>
            </tr>

            {/* COR Row */}
            <tr>
              <th scope="row">2</th>
              <td>COR</td>
              <td>
                <input
                  type="file"
                  name="cor"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  disabled={uploadStatus.cor.uploaded}
                />
              </td>
              <td>
                {uploadStatus.cor.uploaded
                  ? getOriginalFileName(uploadStatus.cor.filePath)
                  : (files.cor ? files.cor.name : 'No file selected')}
              </td>
              <td>
                {uploadStatus.cor.uploaded ? (
                  <span className="text-success">Uploaded</span>
                ) : (
                  <Button 
                    variant="primary" 
                    onClick={() => handleUpload('cor')}
                    disabled={uploadStatus.cor.uploading || !files.cor}
                  >
                    {uploadStatus.cor.uploading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Uploading...
                      </>
                    ) : (
                      'Upload'
                    )}
                  </Button>
                )}
                {uploadStatus.cor.error && (
                  <div className="text-danger mt-2">{uploadStatus.cor.error}</div>
                )}
              </td>
            </tr>

            {/* Grade Row */}
            <tr>
              <th scope="row">3</th>
              <td>Grade</td>
              <td>
                <input
                  type="file"
                  name="grade"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  disabled={uploadStatus.grade.uploaded}
                />
              </td>
              <td>
                {uploadStatus.grade.uploaded
                  ? getOriginalFileName(uploadStatus.grade.filePath)
                  : (files.grade ? files.grade.name : 'No file selected')}
              </td>
              <td>
                {uploadStatus.grade.uploaded ? (
                  <span className="text-success">Uploaded</span>
                ) : (
                  <Button 
                    variant="primary" 
                    onClick={() => handleUpload('grade')}
                    disabled={uploadStatus.grade.uploading || !files.grade}
                  >
                    {uploadStatus.grade.uploading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Uploading...
                      </>
                    ) : (
                      'Upload'
                    )}
                  </Button>
                )}
                {uploadStatus.grade.error && (
                  <div className="text-danger mt-2">{uploadStatus.grade.error}</div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {/* Display Uploaded Files */}
      <div>
      <h5>Uploaded Files:</h5>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
  {/* Application Form */}
  {uploadStatus.application_form.uploaded ? (
    <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <div>
        Application Form: 
        <a 
          href={`https://bsusms.online/api/uploads/${uploadStatus.application_form.filePath.split('/').pop()}`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {getOriginalFileName(uploadStatus.application_form.filePath)}
        </a>
      </div>
      <span style={{ color: getStatusColor(files?.application_form_status) }}> {files?.application_form_status}</span>  
    </li>
  ) : (
    <li style={{ marginBottom: '10px' }}>Application Form not uploaded.</li>
  )}

  {/* COR */}
  {uploadStatus.cor.uploaded ? (
    <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <div>
        COR: 
        <a 
          href={`https://bsusms.online/api/uploads/${uploadStatus.cor.filePath.split('/').pop()}`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {getOriginalFileName(uploadStatus.cor.filePath)}
        </a>
      </div>
      <span style={{ color: getStatusColor(files?.cor_status) }}> {files?.cor_status}</span>
    </li>
  ) : (
    <li style={{ marginBottom: '10px' }}>COR not uploaded.</li>
  )}

  {/* Grade */}
  {uploadStatus.grade.uploaded ? (
    <li style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <div>
        Grade: 
        <a 
          href={`https://bsusms.online/api/uploads/${uploadStatus.grade.filePath.split('/').pop()}`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {getOriginalFileName(uploadStatus.grade.filePath)}
        </a>
      </div>
      <span style={{ color: getStatusColor(files?.grade_status) }}> {files?.grade_status}</span>
    </li>
  ) : (
    <li style={{ marginBottom: '10px' }}>Grade not uploaded.</li>
  )}
</ul>

    </div>
    </Container>
  );
};

export default Home;

