import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUnlock, faQuestionCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Signup from "../components/usercomponents/signup.js";
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import ReCAPTCHA from "react-google-recaptcha";





const LoginPage = () => {
    const navigate = useNavigate();
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showStepper, setShowStepper] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
    };

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://bsusms.online/api/signin', {
                srCode: username,
                password
            });
    
            if (response.status === 200) {
                const { token, user } = response.data;
                localStorage.setItem('authToken', token);
                localStorage.setItem('userInfo', JSON.stringify(user));
                localStorage.setItem('userEmail', user.email); // Store the user's email
                navigate('/dashboard');
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.error || 'Login failed. Please try again.');
            } else {
                setErrorMessage('Unable to connect to the server. Please try again later.');
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleSignUpClick = () => {
        setShowStepper(true);
        document.body.classList.add('blur-background');
    };

    const handleCloseStepper = () => {
        setShowStepper(false);
        document.body.classList.remove('blur-background');
    };

    return (
        <div style={{ padding: '0px' }}>
            <img
                src="images/student_scholarship.png"
                alt="Student Scholarship"
                style={{ width: '100%', marginBottom: '20px' }}
            />
            <div className="card-header text-center">Student Portal Login</div>
            <div className="card-body">
                <div className="container-md" style={{ maxWidth: '600px' }}>
                    <div className="row">
                        <div className="col">
                            <div className="card border-successx"
                                style={{ border: '1px solid #eee', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '30px' }}>
                                <div className="card-body">
                                    <h5 className="card-title">Please Login</h5>
                                    <hr />
                                    <form>
                                        <div className="form-group row" style={{ marginBottom: '10px' }}>
                                            <div className="col-sm-12">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm auth"
                                                    id="input-username"
                                                    placeholder="Username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="input-group mb-3">
                                                    <input
                                                        type={passwordInputType}
                                                        className="form-control form-control-sm auth"
                                                        id="input-password"
                                                        placeholder="Password"
                                                        aria-label="password"
                                                        aria-describedby="button-addon2"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-sm text-muted"
                                                            id="button-addon2"
                                                            onClick={togglePasswordVisibility}
                                                            style={{ border: '1px solid #ced4da' }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={passwordInputType === 'password' ? faEye : faEyeSlash}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <small id="passwordHelpBlock" className="form-text text-muted">
                                                    * Password is case sensitive
                                                </small>
                                                <div className="col-sm-12 d-flex justify-content-center" style={{ marginTop: '20px' }}>
                                                <ReCAPTCHA sitekey="6LdfgW8qAAAAAEnNN6nkPuiHdUK2ha5weR-opPn9"
                                                //    onChange={onChange}
                                                />
                                                </div>
                                            </div>
                                        </div>

                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}

                                        <div className="clearfix text-center" style={{ marginTop: '30px' }}>
                                            <button
                                                type="button"
                                                className="btn btn-success btn-sm pull-rightx rounded-pill shadow-sm"
                                                style={{ width: '130px' }}
                                                disabled={!username || !password}
                                                onClick={handleLogin}
                                            >
                                                <FontAwesomeIcon icon={faUnlock} /> Sign in
                                            </button>
                                        </div>
                                        <hr />
                                        <button
                                            type="button"
                                            className="btn btn-light text-success btn-sm"
                                            data-backdrop="static"
                                            data-toggle="modal"
                                            data-target="#forgotPasswordModal"
                                        >
                                            <FontAwesomeIcon icon={faQuestionCircle} /> Forgot password? Click here
                                        </button>
                                        <button
                                            variant="contained"
                                            onClick={handleSignUpClick}
                                            type="button"
                                            className="btn btn-light text-success btn-sm"
                                            data-backdrop="static"
                                            data-toggle="modal"
                                            data-target="#signupModal"
                                        >
                                            <FontAwesomeIcon icon={faEnvelope} /> Sign Up
                                        </button>
                                        <Signup open={showStepper} onClose={handleCloseStepper} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
