import React, { useState } from 'react';
import { replace, useNavigate } from 'react-router-dom';
import ChangePasswordModal from './ChangePasswordModal'; // Import the modal
import './topStyle.css';

const Top = ({ userEmail }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    console.log("Logging out...");
    navigate('/login', {replace: true});
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark">
        <a 
          className="navbar-brand d-flex align-items-center text-nowrap" 
          href="https://batstate-u.edu.ph" 
          style={{ marginLeft: '20px' }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/images/bsu_logo.png`} 
            width="50" 
            height="50" 
            alt="BSU Logo" 
            style={{ marginRight: '20px' }} 
          />
          Batangas State University Scholarship Program
        </a>
      </nav>
      <nav className="navbar navbar-light bg-light" style={{ marginLeft: '20px', marginRight: '20px' }}>
        <button
          className="btn btn-light btn-sm rounded-pill"
          onClick={() => setModalOpen(true)} // Open modal on click
          style={{ border: '1px solid #ced4da' }}
        >
          <span className="fa-stack zoom">
            <i className="fa fa-circle fa-stack-2x" style={{ color: '#eee' }}></i>
            <i className="fa fa-key fa-stack-1x fa-inverse text-muted"></i>
          </span>
          Change Password
        </button>

        <button
          className="btn btn-light btn-sm rounded-pill"
          onClick={handleLogout}
          style={{ border: '1px solid #ced4da' }}
        >
          <span className="fa-stack zoom">
            <i className="fa fa-circle fa-stack-2x" style={{ color: '#eee' }}></i>
            <i className="fa fa-power-off fa-stack-1x fa-inverse text-muted"></i>
          </span>
          Sign-out
        </button>
      </nav>

      {/* Include the Change Password Modal */}
      <ChangePasswordModal open={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Top;
