import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook to fetch gender data from the API
export const useGenderData = () => {
  const [genderData, setGenderData] = useState([]);

  useEffect(() => {
    const fetchGenderData = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/gender-distribution"
        );
        setGenderData(response.data);
      } catch (error) {
        console.error("Error fetching gender data:", error);
      }
    };

    fetchGenderData();
  }, []);

  return genderData;
};
