import { useState, useEffect } from "react";
import axios from "axios";

// Custom hook to fetch status data from the API
export const useStatusPie = () => {  // Renamed the hook to start with 'use'
  const [statusPieData, setStatusPie] = useState([]);

  useEffect(() => {
    const fetchStatusPie = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/user-status-pie"
        );
        setStatusPie(response.data);
      } catch (error) {
        console.error("Error fetching status data:", error);
      }
    };

    fetchStatusPie();
  }, []);

  return statusPieData;
};

