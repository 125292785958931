// // src/components/AdminDashboard.js
// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Typography,
//   Box,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Button,
//   CircularProgress,
//   Link,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";

// const capitalizeFirstLetter = (string) => {
//   if (!string) return "";
//   return string.charAt(0).toUpperCase() + string.slice(1);
// };

// const getOriginalFileName = (filePath) => {
//   if (!filePath) return "";
//   const filename = filePath.split("/").pop();
//   const hyphenIndex = filename.indexOf("-");
//   return hyphenIndex !== -1 ? filename.substring(hyphenIndex + 1) : filename;
// };

// const AdminDashboard = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [actionLoading, setActionLoading] = useState({});
//   const [error, setError] = useState(null);
//   const [dialog, setDialog] = useState({
//     open: false,
//     userId: null,
//     fileType: null,
//     action: null,
//   });
//   const [paginationModel, setPaginationModel] = useState({
//     page: 0,
//     pageSize: 10,
//   });

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.get(
//         "${process.env.BSU_SMS_2024}/api/admin/uploads"
//       );

//       console.log("Fetched Users:", response.data.users);

//       setUsers(response.data.users);
//       setLoading(false);
//     } catch (err) {
//       console.error("Error fetching users:", err);
//       setError("Failed to fetch users.");
//       setLoading(false);
//     }
//   };

//   const handleAction = (userId, fileType, action) => {
//     if (!fileType) {
//       console.error(`fileType is null or undefined for userId: ${userId}`);
//       setError("Invalid file type specified.");
//       return;
//     }
//     console.log(
//       `Action Initiated: UserID=${userId}, FileType=${fileType}, Action=${action}`
//     );
//     setDialog({ open: true, userId, fileType, action });
//   };

//   const confirmAction = async () => {
//     const { userId, fileType, action } = dialog;
//     console.log(
//       `Confirm Action: UserID=${userId}, FileType=${fileType}, Action=${action}`
//     );

//     setActionLoading((prev) => ({ ...prev, [`${userId}-${fileType}`]: true }));

//     try {
//       const response = await axios.patch(
//         `${process.env.BSU_SMS_2024}/api/admins/uploads/${userId}/${fileType}`,
//         {
//           status: action,
//         }
//       );

//       console.log("Patch Response:", response.data);

//       setUsers((prevUsers) =>
//         prevUsers.map((user) => {
//           if (user.id === userId) {
//             const statusField = `${fileType}`;
//             return { ...user, [statusField]: action };
//           }
//           return user;
//         })
//       );

//       setDialog({ open: false, userId: null, fileType: null, action: null });
//       setActionLoading((prev) => ({
//         ...prev,
//         [`${userId}-${fileType}`]: false,
//       }));
//       setError(null);
//     } catch (err) {
//       console.error(
//         `Error performing action on ${fileType} for user ${userId}:`,
//         err
//       );
//       setError(`Failed to ${action} ${fileType} for user ID ${userId}.`);
//       setActionLoading((prev) => ({
//         ...prev,
//         [`${userId}-${fileType}`]: false,
//       }));
//       setDialog({ open: false, userId: null, fileType: null, action: null });
//     }
//   };

//   const handleCloseDialog = () => {
//     setDialog({ open: false, userId: null, fileType: null, action: null });
//   };

//   if (loading) {
//     return (
//       <Container maxWidth="md" sx={{ textAlign: "center", mt: 5 }}>
//         <CircularProgress />
//         <Typography variant="h6" sx={{ mt: 2 }}>
//           Loading users...
//         </Typography>
//       </Container>
//     );
//   }

//   // Define columns for DataGrid without the index column
//   const columns = [
//     { field: "lastName", headerName: "Username", width: 150 },
//     { field: "email", headerName: "Email", width: 200 },
//     {
//       field: "application_form",
//       headerName: "Application Form",
//       width: 250,
//       renderCell: (params) => {
//         const user = params.row;
//         return user.application_form ? (
//           <>
//             <Link
//               href={`${process.env.BSU_SMS_2024}/${user.application_form}`}
//               target="_blank"
//               rel="noopener noreferrer"
//               underline="hover"
//             >
//               {getOriginalFileName(user.application_form)}
//             </Link>
//             <Typography variant="body2">
//               <strong>Status:</strong>{" "}
//               {capitalizeFirstLetter(user.application_form_status)}
//             </Typography>
//             {user.application_form_status === "pending" && (
//               <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
//                 <Button
//                   variant="contained"
//                   color="success"
//                   size="small"
//                   startIcon={<CheckIcon />}
//                   sx={{ mr: 1 }}
//                   onClick={() =>
//                     handleAction(user.id, "application_form_status", "approved")
//                   }
//                   disabled={actionLoading[`${user.id}-application_form_status`]}
//                 >
//                   {actionLoading[`${user.id}-application_form_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Approve"
//                   )}
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="error"
//                   size="small"
//                   startIcon={<CloseIcon />}
//                   onClick={() =>
//                     handleAction(user.id, "application_form_status", "denied")
//                   }
//                   disabled={actionLoading[`${user.id}-application_form_status`]}
//                 >
//                   {actionLoading[`${user.id}-application_form_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Deny"
//                   )}
//                 </Button>
//               </Box>
//             )}
//           </>
//         ) : (
//           "No File Uploaded"
//         );
//       },
//     },
//     {
//       field: "cor",
//       headerName: "COR",
//       width: 250,
//       renderCell: (params) => {
//         const user = params.row;
//         return user.cor ? (
//           <>
//             <Link
//               href={`${process.env.BSU_SMS_2024}/${user.cor}`}
//               target="_blank"
//               rel="noopener noreferrer"
//               underline="hover"
//             >
//               {getOriginalFileName(user.cor)}
//             </Link>
//             <Typography variant="body2">
//               <strong>Status:</strong> {capitalizeFirstLetter(user.cor_status)}
//             </Typography>
//             {user.cor_status === "pending" && (
//               <Box sx={{ mt: 1 }}>
//                 <Button
//                   variant="contained"
//                   color="success"
//                   size="small"
//                   startIcon={<CheckIcon />}
//                   sx={{ mr: 1 }}
//                   onClick={() =>
//                     handleAction(user.id, "cor_status", "approved")
//                   }
//                   disabled={actionLoading[`${user.id}-cor_status`]}
//                 >
//                   {actionLoading[`${user.id}-cor_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Approve"
//                   )}
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="error"
//                   size="small"
//                   startIcon={<CloseIcon />}
//                   onClick={() => handleAction(user.id, "cor_status", "denied")}
//                   disabled={actionLoading[`${user.id}-cor_status`]}
//                 >
//                   {actionLoading[`${user.id}-cor_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Deny"
//                   )}
//                 </Button>
//               </Box>
//             )}
//           </>
//         ) : (
//           "No File Uploaded"
//         );
//       },
//     },
//     {
//       field: "grade",
//       headerName: "Grade",
//       width: 250,
//       renderCell: (params) => {
//         const user = params.row;
//         return user.grade ? (
//           <>
//             <Link
//               href={`${process.env.BSU_SMS_2024}/${user.grade}`}
//               target="_blank"
//               rel="noopener noreferrer"
//               underline="hover"
//             >
//               {getOriginalFileName(user.grade)}
//             </Link>
//             <Typography variant="body2">
//               <strong>Status:</strong>{" "}
//               {capitalizeFirstLetter(user.grade_status)}
//             </Typography>
//             {user.grade_status === "pending" && (
//               <Box sx={{ mt: 1 }}>
//                 <Button
//                   variant="contained"
//                   color="success"
//                   size="small"
//                   startIcon={<CheckIcon />}
//                   sx={{ mr: 1 }}
//                   onClick={() =>
//                     handleAction(user.id, "grade_status", "approved")
//                   }
//                   disabled={actionLoading[`${user.id}-grade_status`]}
//                 >
//                   {actionLoading[`${user.id}-grade_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Approve"
//                   )}
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="error"
//                   size="small"
//                   startIcon={<CloseIcon />}
//                   onClick={() =>
//                     handleAction(user.id, "grade_status", "denied")
//                   }
//                   disabled={actionLoading[`${user.id}-grade_status`]}
//                 >
//                   {actionLoading[`${user.id}-grade_status`] ? (
//                     <CircularProgress size={20} color="inherit" />
//                   ) : (
//                     "Deny"
//                   )}
//                 </Button>
//               </Box>
//             )}
//           </>
//         ) : (
//           "No File Uploaded"
//         );
//       },
//     },
//   ];

//   return (
//     <Container maxWidth="lg" sx={{ mt: 4 }}>
//       <Typography variant="h4" gutterBottom>
//         Admin Dashboard - User Uploads
//       </Typography>

//       {error && (
//         <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
//           {error}
//         </Alert>
//       )}

//       <Box sx={{ height: 600, width: "100%" }}>
//         <DataGrid
//           rows={users}
//           columns={columns}
//           pageSize={paginationModel.pageSize}
//           rowsPerPageOptions={[5, 10, 20]}
//           pagination
//           paginationMode="client"
//           onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
//           paginationModel={paginationModel}
//           disableSelectionOnClick
//           getRowId={(row) => row.id}
//           sx={{
//             "& .MuiDataGrid-cell": {
//               alignItems: "flex-start",
//               whiteSpace: "normal",
//               wordWrap: "break-word",
//             },
//           }}
//         />
//       </Box>

//       {/* Confirmation Dialog */}
//       <Dialog
//         open={dialog.open}
//         onClose={handleCloseDialog}
//         aria-labelledby="confirmation-dialog-title"
//         aria-describedby="confirmation-dialog-description"
//       >
//         <DialogTitle id="confirmation-dialog-title">
//           {dialog.action === "approved" ? "Approve" : "Deny"} File
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="confirmation-dialog-description">
//             Are you sure you want to {dialog.action} the{" "}
//             <strong>
//               {dialog.fileType
//                 ? capitalizeFirstLetter(dialog.fileType.replace("_status", ""))
//                 : "Unknown File Type"}
//             </strong>{" "}
//             for user ID <strong>{dialog.userId}</strong>?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={confirmAction}
//             color={dialog.action === "approved" ? "success" : "error"}
//             variant="contained"
//             autoFocus
//           >
//             {dialog.action === "approved" ? "Approve" : "Deny"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default AdminDashboard;

// src/components/AdminDashboard.js
// src/components/AdminDashboard.js

// src/components/AdminDashboard.js
// src/components/AdminDashboard.js
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Link,
  TextField,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Helper function to extract original filename
const getOriginalFileName = (filePath) => {
  if (!filePath) return "";
  const filename = filePath.split("/").pop(); // e.g., "1633036800000-originalname.pdf"
  const hyphenIndex = filename.indexOf("-");
  return hyphenIndex !== -1 ? filename.substring(hyphenIndex + 1) : filename;
};

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState({}); // Track loading per action
  const [error, setError] = useState(null);

  const [openModal, setOpenModal] = useState(false); // Modal open state
  const [currentUser, setCurrentUser] = useState(null); // Current user for which modal is opened
  const [message, setMessage] = useState(""); // Input value for message

  const [dialog, setDialog] = useState({
    open: false,
    userId: null,
    fileType: null,
    action: null,
  });

  const handleOpenModal = (user) => {
    setCurrentUser(user);
    setMessage(user.message || ""); // Pre-fill existing message if available
    setOpenModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentUser(null);
    setMessage(""); // Clear message input on close
  };

  // Save message to MySQL via API
  const handleSaveMessage = async () => {
    if (!currentUser) return;

    try {
      axios.put(`https://bsusms.online/api/users/${currentUser.id}/message`, {
        message,
      });
      // Update the grid data after successful save (reload or update locally)
      currentUser.message = message; // Update the message locally
      handleCloseModal(); // Close modal after saving
    } catch (error) {
      console.error("Failed to save message:", error);
    }
  };

  // Define pagination state
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // Current page number (zero-based)
    pageSize: 10, // Number of rows per page
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://bsusms.online/api/admin/uploads"
      );

      // Log the fetched users for debugging
      console.log("Fetched Users:", response.data.users);

      setUsers(response.data.users);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to fetch users.");
      setLoading(false);
    }
  };

  const handleAction = (userId, fileType, action) => {
    if (!fileType) {
      console.error(`fileType is null or undefined for userId: ${userId}`);
      setError("Invalid file type specified.");
      return;
    }
    console.log(
      `Action Initiated: UserID=${userId}, FileType=${fileType}, Action=${action}`
    );
    setDialog({ open: true, userId, fileType, action });
  };

  const confirmAction = async () => {
    const { userId, fileType, action } = dialog;
    console.log(
      `Confirm Action: UserID=${userId}, FileType=${fileType}, Action=${action}`
    );

    setActionLoading((prev) => ({ ...prev, [`${userId}-${fileType}`]: true }));

    try {
      const response = await axios.patch(
        `https://bsusms.online/api/admins/uploads/${userId}/${fileType}`,
        {
          status: action,
        }
      );

      console.log("Patch Response:", response.data);

      // Update the user's status in the state
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === userId) {
            const statusField = `${fileType}`;
            return { ...user, [statusField]: action };
          }
          return user;
        })
      );

      setDialog({ open: false, userId: null, fileType: null, action: null });
      setActionLoading((prev) => ({
        ...prev,
        [`${userId}-${fileType}`]: false,
      }));
      setError(null);
    } catch (err) {
      console.error(
        `Error performing action on ${fileType} for user ${userId}:`,
        err
      );
      setError(`Failed to ${action} ${fileType} for user ID ${userId}.`);
      setActionLoading((prev) => ({
        ...prev,
        [`${userId}-${fileType}`]: false,
      }));
      setDialog({ open: false, userId: null, fileType: null, action: null });
    }
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, userId: null, fileType: null, action: null });
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 5 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading users...
        </Typography>
      </Container>
    );
  }

  // Define columns for DataGrid
  const columns = [
    { field: "email", headerName: "SR CODE", flex: 1 },
    { field: "scholar", headerName: "Scholarship Type", flex: 1 },

    {
      field: "application_form",
      headerName: "Application Form",
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const status = user.application_form_status
          ? user.application_form_status.toLowerCase()
          : "";
        console.log(
          `Rendering Application Form for User ID: ${user.id}, Status: ${status}`
        );

        const capitalizeFirstLetter = (string) => {
          if (typeof string !== "string") return "";
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {user.application_form ? (
              <>
                <Link
                  href={`https://bsusms.online/api/uploads/${user.application_form.split('/').pop()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  sx={{
                    margin: "-50px 0", // Remove any margin
                    padding: 0, // Remove padding if necessary
                    display: "inline", // Ensure it's treated like an inline element
                    textAlign: "right",
                  }}
                >
                  {getOriginalFileName(user.application_form)}
                </Link>
                <Typography
                  variant="body2"
                  style={{
                    color:
                      user.application_form_status === "pending"
                        ? "orange"
                        : user.application_form_status === "approved"
                          ? "green"
                          : "red",
                  }}
                >
                  <strong>Status:</strong>{" "}
                  {capitalizeFirstLetter(user.application_form_status)}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="error">
                No File Uploaded
              </Typography>
            )}

            {status === "pending" && (
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<CheckIcon />}
                  onClick={() =>
                    handleAction(user.id, "application_form_status", "approved")
                  }
                  disabled={actionLoading[`${user.id}-application_form_status`]}
                >
                  {actionLoading[`${user.id}-application_form_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Approve"
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() =>
                    handleAction(user.id, "application_form_status", "denied")
                  }
                  disabled={actionLoading[`${user.id}-application_form_status`]}
                >
                  {actionLoading[`${user.id}-application_form_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Deny"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        );
      },
    },

    {
      field: "cor",
      headerName: "COR",
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const status = user.cor_status ? user.cor_status.toLowerCase() : "";
        console.log(`Rendering COR for User ID: ${user.id}, Status: ${status}`);

        const capitalizeFirstLetter = (string) => {
          if (typeof string !== "string") return "";
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {user.cor ? (
              <>
                <Link
                  href={`https://bsusms.online/api/uploads/${user.cor.split('/').pop()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  sx={{
                    margin: "-50px 0", // Remove any margin
                    padding: 0, // Remove padding if necessary
                    display: "inline", // Ensure it's treated like an inline element
                    textAlign: "right",
                  }}
                >
                  {getOriginalFileName(user.cor)}
                </Link>
                <Typography
                  variant="body2"
                  style={{
                    color:
                      user.cor_status === "pending"
                        ? "orange"
                        : user.cor_status === "approved"
                          ? "green"
                          : "red",
                  }}
                >
                  <strong>Status:</strong>{" "}
                  {capitalizeFirstLetter(user.cor_status)}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="error">
                No File Uploaded
              </Typography>
            )}

            {status === "pending" && (
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<CheckIcon />}
                  onClick={() =>
                    handleAction(user.id, "cor_status", "approved")
                  }
                  disabled={actionLoading[`${user.id}-cor_status`]}
                >
                  {actionLoading[`${user.id}-cor_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Approve"
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() => handleAction(user.id, "cor_status", "denied")}
                  disabled={actionLoading[`${user.id}-cor_status`]}
                >
                  {actionLoading[`${user.id}-cor_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Deny"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        const status = user.grade_status ? user.grade_status.toLowerCase() : "";
        console.log(
          `Rendering Grade for User ID: ${user.id}, Status: ${status}`
        );
        const capitalizeFirstLetter = (string) => {
          if (typeof string !== "string") return "";
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {user.grade ? (
              <>
                <Link
                  href={`https://bsusms.online/api/uploads/${user.grade.split('/').pop()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  sx={{
                    margin: "-50px 0", // Remove any margin
                    padding: 0, // Remove padding if necessary
                    display: "inline", // Ensure it's treated like an inline element
                    textAlign: "right",
                  }}
                >
                  {getOriginalFileName(user.grade)}
                </Link>

                <Typography
                  variant="body2"
                  style={{
                    color:
                      user.grade_status === "pending"
                        ? "orange"
                        : user.grade_status === "approved"
                          ? "green"
                          : "red",
                  }}
                >
                  <strong>Status:</strong>{" "}
                  {capitalizeFirstLetter(user.grade_status)}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="error">
                No File Uploaded
              </Typography>
            )}

            {status === "pending" && (
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<CheckIcon />}
                  onClick={() =>
                    handleAction(user.id, "grade_status", "approved")
                  }
                  disabled={actionLoading[`${user.id}-grade_status`]}
                >
                  {actionLoading[`${user.id}-grade_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Approve"
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  startIcon={<CloseIcon />}
                  onClick={() =>
                    handleAction(user.id, "grade_status", "denied")
                  }
                  disabled={actionLoading[`${user.id}-grade_status`]}
                >
                  {actionLoading[`${user.id}-grade_status`] ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Deny"
                  )}
                </Button>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        return (
          <Button variant="contained" onClick={() => handleOpenModal(user)}>
            <MessageIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2, // Added padding for the main container
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          alignItems: "center",
          mb: 2, // Added margin-bottom for spacing below the title
        }}
      >
        <Typography
          sx={{ flexGrow: 1 }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Applicant
        </Typography>
      </Box>

      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ height: 800, width: "100%", mb: 2 }}>
        {" "}
        {/* Added margin-bottom */}
        <DataGrid
          rows={users}
          rowHeight={140}
          columns={columns}
          pageSize={paginationModel.pageSize}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          paginationMode="client"
          onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
          paginationModel={paginationModel}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          sx={{
            "& .MuiDataGrid-cell": {
              alignItems: "flex-start",
              whiteSpace: "normal",
              wordWrap: "break-word",
              padding: "8px", // Added padding inside the cells
            },
          }}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: 400,
          }}
        >
          <Typography
            variant="body2"
            gutterBottom
            sx={{ textAlign: "center", mb: 2 }} // Added margin-bottom for spacing
          >
            Notify {currentUser?.email}
          </Typography>
          <TextField
            fullWidth
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            sx={{ mb: 2 }} // Added margin-bottom for spacing below the text field
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveMessage}
              color="success"
              variant="contained"
              sx={{ ml: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          {dialog.action === "approved" ? "Approve" : "Deny"} File
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            Are you sure you want to {dialog.action} the{" "}
            <strong>
              {dialog.fileType
                ? capitalizeFirstLetter(dialog.fileType.replace("_status", ""))
                : "Unknown File Type"}
            </strong>{" "}
            for user ID <strong>{dialog.userId}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmAction}
            color={dialog.action === "approved" ? "success" : "error"}
            variant="contained"
            autoFocus
          >
            {dialog.action === "approved" ? "Approve" : "Deny"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDashboard;
