// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { TextField, Button } from '@mui/material';
// import "./try.css"
// import Grid from '@mui/material/Grid';


// const AdminDashboard = () => {
//   const [announcements, setAnnouncements] = useState([]);
//   const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

//   useEffect(() => {
//     axios.get('${process.env.BSU_SMS_2024}/api/announcements')
//       .then(response => setAnnouncements(response.data))
//       .catch(error => console.error('Error fetching announcements:', error));
//   }, []);

//   const handleEdit = (id) => {
//     const announcement = announcements.find(a => a.id === id);
//     setSelectedAnnouncement(announcement);
//   };

//   const handleChange = (e) => {
//     setSelectedAnnouncement({
//       ...selectedAnnouncement,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = () => {
//     if (selectedAnnouncement) {
//       axios.put(`${process.env.BSU_SMS_2024}/api/announcements/${selectedAnnouncement.id}`, selectedAnnouncement)
//         .then(() => alert('Announcement updated!'))
//         .catch(error => console.error('Error updating announcement:', error));
//     }
//   };

  

//   return (
//     <div className="form">
//       <span className="form-title">Update Advisory</span>

//       <div className="dcontainer">
//                 {announcements.map(announcement => (
//           <Button  key={announcement.id} onClick={() => handleEdit(announcement.id)}>
//             Edit {announcement.title}
//           </Button>
//         ))}
    
//       {selectedAnnouncement && (
//          <Grid container spacing={2} direction="column" alignItems="center">
//          <Grid item xs={12} style={{ width: '100%' }}>
//            <TextField
//              fullWidth
//              name="title"
//              label="Title"
//              value={selectedAnnouncement.title}
//              onChange={handleChange}
//            />
//          </Grid>
//          <Grid item xs={12} style={{ width: '100%' }}>
//            <TextField
//              fullWidth
//              name="content"
//              label="Content"
//              multiline
//              rows={4}
//              value={selectedAnnouncement.content}
//              onChange={handleChange}
//            />
//          </Grid>
//          <Grid item xs={12} style={{ width: '100%' }}>
//            <Button
//              fullWidth
//              variant="contained"
//              color="primary"
//              onClick={handleSubmit}
//            >
//              Save Changes
//            </Button>
//          </Grid>
//        </Grid>
//       )}
//         </div>
//     </div>
//   );
// };

// export default AdminDashboard;


import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';

export default function OutlinedCard() {
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  // Fetch announcements from API
  useEffect(() => {
    axios
      .get('https://bsusms.online/api/announcements')
      .then((response) => setAnnouncements(response.data))
      .catch((error) => console.error('Error fetching announcements:', error));
  }, []);

  // Handle editing announcement
  const handleEdit = (id) => {
    const announcement = announcements.find((a) => a.id === id);
    setSelectedAnnouncement(announcement);
  };

  // Handle form changes
  const handleChange = (e) => {
    setSelectedAnnouncement({
      ...selectedAnnouncement,
      [e.target.name]: e.target.value,
    });
  };

  // Handle submit to update announcement
  const handleSubmit = () => {
    if (selectedAnnouncement) {
      axios
        .put(
          `https://bsusms.online/api/announcements/${selectedAnnouncement.id}`,
          selectedAnnouncement
        )
        .then(() => alert('Announcement updated!'))
        .catch((error) =>
          console.error('Error updating announcement:', error)
        );
    }
  };
  
  return (
    <Box sx={{ minWidth: 275, marginBottom:2 }}>
      <Card variant="outlined">
        <CardContent  sx={{ textAlign: 'center' }}>
          {/* Main Card Title */}
          <Typography variant="h6" component="div">
            Update Advisory
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
            Update Advisory in Student Dashboard
          </Typography>

          {/* List of announcements */}
          {announcements.map((announcement) => (
            <div key={announcement.id}>
              {/* <Typography variant="body1">{announcement.title}</Typography> */}
              <Button 
                variant="outlined" 
                size="small" 
                onClick={() => handleEdit(announcement.id)}>
                Edit
              </Button>
            </div>
          ))}

          {/* Form for editing announcement */}
          {selectedAnnouncement && (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Edit Announcement
              </Typography>
              <TextField
                label="Title"
                name="title"
                value={selectedAnnouncement.title || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Content"
                name="content"
                value={selectedAnnouncement.content || ''}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Save Changes
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
