import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faUnlock,
  faQuestionCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom"; // Import useNavigate
// import ReCAPTCHA from "react-google-recaptcha";


const Login_admin = () => {
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
    };

    const handleLogin = async () => {
        try {
            // Send the login request to the backend
            const response = await axios.post('https://bsusms.online/api/signin/admin', {
                email: username, // Assuming the backend expects 'email' field
                password
            });
            
            // Handle successful login
            if (response.status === 200) {
                const { token, user } = response.data;
                localStorage.setItem('adminToken', token);  // Store the JWT token
                // localStorage.setItem('userInfo', JSON.stringify(user));
                navigate('/bsu-sms');
                // Redirect user to the dashboard or other page after successful login
            }
        } catch (error) {
            if (error.response) {
                // Handle error from backend
                setErrorMessage(error.response.data.error || 'Login failed. Please try again.');
            } else {
                setErrorMessage('Unable to connect to the server. Please try again later.');
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

  return (
    <div style={{ padding: "0px" }}>
      <img
       
        src="images/bsu_logo.png"
        alt="Student Scholarship"
        style={{ width: "100px", display: "block", margin: "0 auto 20px", marginTop:'20px' }}
      />
      <div className="card-body">
        <div className="container-md" style={{ maxWidth: "600px", border: "1px solid #eee",marginTop: "10px",  marginBottom: "30px",  }}>
          <div className="row">
            <div className="col">
            {/* <div
              className="card border-successx"
              style={{
                border: "1px solid #eee",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "10px",
                marginBottom: "30px",
              }}
            ></div> */}
            <div className="card-body">
              <h5 className="card-title col-sm-12 d-flex justify-content-center mt-3">Admin Login</h5>
              <hr />
              <form>
                <div
                  className="form-group row"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control form-control-sm auth"
                      id="input-username"
                      placeholder="Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="input-group mb-3">
                      <input
                          type={passwordInputType}
                        className="form-control form-control-sm auth"
                        id="input-password"
                        placeholder="Password"
                        aria-label="password"
                        aria-describedby="button-addon2"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={handleKeyPress}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-light btn-sm text-muted"
                          id="button-addon2"
                          onClick={togglePasswordVisibility}
                          style={{ border: "1px solid #ced4da" }}
                        >
                          <FontAwesomeIcon
                      icon={
                        passwordInputType === "password" ? faEye : faEyeSlash
                      }
                    />
                        </button>   
                      </div>
                    </div>
                    
                   
                  </div>
                </div>

                {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

                <div
                  className="clearfix text-center"
                  style={{ marginTop: "30px" }}
                >
                  <button
                    type="button"
                    className="btn btn-success btn-sm pull-rightx rounded-pill shadow-sm"
                    style={{ width: "130px" }}
                      disabled={!username || !password}
                      onClick={handleLogin}
                  >
                    <FontAwesomeIcon icon={faUnlock} /> Sign in
                  </button>
                </div>
                <hr />
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login_admin;