// import React , { useEffect, useState } from 'react'
// import axios from 'axios';

// const Header = () => {

//   const [userData, setUserData] = useState(null);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         // Get the token from localStorage or sessionStorage
//         const token = localStorage.getItem('authToken'); // Ensure this matches the token key in localStorage
//         console.log('Token used in request:', token); // Check if the token is being retrieved

//         if (!token) {
//           setError('No token found. Please log in.');
//           return;
//         }

//         // Make a request to the backend with the token
//         const response = await axios.get('http://localhost:3000/dashboard', {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         });

//         console.log('Response from server:', response); // Log the entire response
//         // Store user data from response
//         setUserData(response.data.user);
//       } catch (err) {
//         console.error('Error fetching user data:', err);
//         setError(`Failed to fetch user data. ${err.response ? err.response.data.error : err.message}`);
//       }
//     };

//     fetchUserData();
//   }, []);

//   if (error) {
//       return <div>{error}</div>;
//   }

//   if (!userData) {
//       return <div>Loading...</div>;
//   }

//       const student = {
//         status: {
//           fetching_enrollment_records: false
//         },
//         results: {
//           profile: {
//             academic: {
//               school: 'COLLEGE',
//               enrollmentstatus: 'ENROLLED',
//               majorname: 'Business Analytics'
//             }
//           }
//         }
//       };

//       const isFetchingEnrollmentRecords = student.status.fetching_enrollment_records;
//       const isEnrolled = student.results.profile.academic.enrollmentstatus === 'ENROLLED';

//   return (
//     <>
//    <div className="container-md" style={{ marginTop: '10px' }}>
//       </div>

//       <div
//       className="student-header row"
//       style={{
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center center',
//         backgroundSize: 'cover',
//         backgroundImage: `url(${process.env.PUBLIC_URL}/images/portal-header6.png)`,
//         minHeight: '250px',
//       }}
//     >
//       <div className="col" style={{ padding: '3px', maxHeight: '200px', backgroundColor: 'transparent' }}>
//         <div
//           className="card"
//           style={{ maxHeight: '200px', border: '0px solid #ced4da', backgroundColor: 'transparent' }}

//         >
//           <div className="row no-gutters">
//             <div className="col-md-2 align-baseline">
//               <div style={{ width: '100%', margin: '5px', position: 'absolute' }}>
//                 <img src={`${process.env.PUBLIC_URL}/images/bsu_logo.png`} alt="Profile" style={{ height: '120px', width: '120px',  marginLeft: '20px'}} />
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="card-body rounded shadow-sm p-3 mb-5 cover-info">
//                 <h5 className="card-title">
//                   <strong>{userData.firstName} {userData.lastName}</strong>
//                 </h5>
//               </div>
//             </div>
//             <div
//               className="col-md-6"
//               style={{
//                 backgroundSize: '2px 2px',
//                 backgroundImage: "url('https://dione.batstate-u.edu.ph/bsulogo_2.png')",
//                 backgroundRepeat: 'no-repeat',
//                 backgroundPosition: '50px center'
//               }}
//             >
//               <div className="card-body rounded cover-info">
//                 {isFetchingEnrollmentRecords && (
//                   <p className="card-text text-muted">
//                     <i className="fa fa-circle-o-notch fa-spin fa-fw text-muted"></i>
//                   </p>
//                 )}
//                 <p className="card-text font-weight-bold" style={{ margin: '0px', padding: '2px' }}>
//                   <i className="fa fa-caret-right text-muted" aria-hidden="true"></i> {userData.semester}
//                 </p>
//                 <p className="card-text" style={{ margin: '0px', padding: '2px' }}>
//                   <i className="fa fa-caret-right text-muted" aria-hidden="true"></i> {userData.department} - {userData.campus}
//                 </p>
//                 <p className="card-text" style={{ margin: '0px', padding: '2px' }}>
//                   <i className="fa fa-caret-right text-muted" aria-hidden="true"></i> <strong>{userData.program}</strong> - {userData.yearLevel}
//                 </p>

//                 <p className="card-text" style={{ border: '0px solid #eee', margin: '0px', padding: '2px' }}>
//                   <i className="fa fa-caret-right text-muted" aria-hidden="true"></i>{' '}
//                   <strong className={isEnrolled ? 'text-danger' : 'text-danger'}>{userData.status}</strong>
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     </>

//   )
// }

// export default Header

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./headerStyle.css";

const Header = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          setError("No token found. Please log in.");
          return;
        }

        const response = await axios.get(
          "https://bsusms.online/api/dashboard",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setUserData(response.data.user);
      } catch (err) {
        setError(
          `Failed to fetch user data. ${err.response ? err.response.data.error : err.message}`
        );
      }
    };

    fetchUserData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!userData) {
    return <div>Loading...</div>;
  }

  const student = {
    status: { fetching_enrollment_records: false },
    results: { profile: { academic: { enrollmentstatus: "ENROLLED" } } },
  };
  const isFetchingEnrollmentRecords =
    student.status.fetching_enrollment_records;
  const isEnrolled =
    student.results.profile.academic.enrollmentstatus === "ENROLLED";

  return (
    <>
      <div className="container-md" style={{ marginTop: "10px" }}></div>

      <div
        className="student-header row"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/portal-header6.png)`,
          minHeight: "200px",
        }}
      >
        {/* Row 1 */}
        <div className="col-12 col-md-2 d-flex align-items-center justify-content-center">
          <div
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              backgroundColor: "#950101",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "36px", // Adjust font size as needed
              fontWeight: "bold", // Make the text bold
              marginTop: "10px", // Adjust margin as needed
              marginBottom: "10px", // Adjust margin as needed
            }}
          >
            {userData.firstName.charAt(0).toUpperCase()}
            {userData.lastName.charAt(0).toUpperCase()}
          </div>
        </div>

        {/* Row 2 */}
        <div className="col-12 col-md-4 second-row p-3 responsive-background">
          <div className="card-body rounded shadow-sm text-center">
            {" "}
            {/* Added text-center here */}
            <h5 className="card-title">
              <strong>
                {userData.firstName} {userData.lastName}
              </strong>
            </h5>
          </div>
        </div>

        {/* Row 3 */}
        <div
          className="col-12 col-md-6 third-row p-3 responsive-background"
          style={{
            backgroundSize: "2px 2px",
            backgroundImage:
              "url('https://dione.batstate-u.edu.ph/bsulogo_2.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50px center",
          }}
        >
          <div className="card-body rounded">
            {isFetchingEnrollmentRecords && (
              <p className="card-text text-muted">
                <i className="fa fa-circle-o-notch fa-spin fa-fw text-muted"></i>
              </p>
            )}
            <p className="card-text font-weight-bold">
              <i
                className="fa fa-caret-right text-muted"
                aria-hidden="true"
              ></i>{" "}
              {userData.semester}
            </p>
            <p className="card-text">
              <i
                className="fa fa-caret-right text-muted"
                aria-hidden="true"
              ></i>{" "}
              {userData.department} - {userData.campus}
            </p>
            <p className="card-text">
              <i
                className="fa fa-caret-right text-muted"
                aria-hidden="true"
              ></i>{" "}
              <strong>{userData.program}</strong> - {userData.yearLevel}
            </p>
            <p className="card-text">
              <i
                className="fa fa-caret-right text-muted"
                aria-hidden="true"
              ></i>{" "}
              <strong className={userData.status === "REGISTERED" ? "text-success" : "text-danger"}>
                {userData.status}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
