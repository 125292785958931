import React from "react";
import { Navigate } from "react-router-dom";

// ProtectedRoute component
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const authToken = localStorage.getItem("adminToken"); // Access the token from localStorage using 'authToken' as key

  return authToken ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/admin-bsu" replace /> // Redirect to login if not authenticated
  );
};

export default ProtectedRoute;